import React, { useEffect, useState, useCallback } from 'react';
import { Box, Paper, Typography, Button, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, Tooltip, Zoom, Fade, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { PersonAdd, Search, Refresh, EmojiPeople, ThumbUp, ThumbDown } from '@mui/icons-material';
import { DynamoDBClient, ScanCommand, UpdateItemCommand } from "@aws-sdk/client-dynamodb";
// import awsconfig from "../../awsconfig";
import { styled, keyframes } from '@mui/system';
import { motion, AnimatePresence } from 'framer-motion';
import { SESClient, SendEmailCommand } from "@aws-sdk/client-ses";
import axios from 'axios';
// import getAwsConfig from "../../awsconfig";


// const client = new DynamoDBClient({
//   region: awsconfig.region,
//   credentials: awsconfig.credentials,
// });

import getAwsConfig from "../../awsconfig";
const awsconfig = await getAwsConfig();
const client = new DynamoDBClient(awsconfig);
const sesClient = new SESClient(awsconfig);


const getEmailTemplate = async (templateName) => {
  try {
    const response = await axios.get(
      `https://00pspww8wd.execute-api.us-east-2.amazonaws.com/get-email-template?templateName=${templateName}`
    );
    //console.log("Temp Email Response status: ", response.status);
    //console.log("Temp Email Response data: ", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching email template:", error);
    return null;
  }
};


const AnimatedTableRow = styled(motion.tr)(({ theme }) => ({
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      transform: 'scale(1.01)',
      boxShadow: theme.shadows[4],
    },
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.selectedOpacity,
    },
  }));
  
  const AnimatedTableBody = styled(TableBody)({
    '& > tr': {
      opacity: 0,
    },
  });
  
  const StyledSearchBar = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[4],
    marginBottom: theme.spacing(3),
    transition: 'box-shadow 0.3s ease-in-out',
    '&:hover': {
      boxShadow: theme.shadows[8],
    },
  }));
  
  const StyledSearchInput = styled('input')(({ theme }) => ({
    flex: 1,
    border: 'none',
    outline: 'none',
    padding: theme.spacing(1),
    fontSize: '1rem',
    backgroundColor: 'transparent',
    transition: 'background-color 0.3s ease-in-out',
    '&:focus': {
      backgroundColor: theme.palette.action.focus,
    },
  }));
  
  const PulseAnimation = keyframes`
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.2);
      opacity: 0.6;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  `;
  
  const TesterIcon = styled(EmojiPeople)(({ theme }) => ({
    fontSize: 60,
    color: theme.palette.primary.main,
    animation: `${PulseAnimation} 2s ease-in-out infinite`,
  }));
  
  const ConfirmationDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
      backgroundColor: theme.palette.background.paper,
      backgroundImage: `linear-gradient(45deg, ${theme.palette.primary.light}, ${theme.palette.success.light})`,
      borderRadius: theme.shape.borderRadius * 2,
      boxShadow: theme.shadows[8],
      padding: theme.spacing(4),
      textAlign: 'center',
    },
    '& .MuiDialogTitle-root': {
      fontSize: '2rem',
      fontWeight: 'bold',
      color: theme.palette.primary.contrastText,
      textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
    },
    '& .MuiDialogContent-root': {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(4),
    },
    '& .MuiDialogActions-root': {
      justifyContent: 'center',
    },
    '& .MuiButton-root': {
      textTransform: 'uppercase',
      fontWeight: 'bold',
      transition: 'transform 0.3s ease-in-out',
      '&:hover': {
        transform: 'scale(1.05)',
      },
    },
  }));

const PendingTestersTable = () => {
  const [pendingTesters, setPendingTesters] = useState([]);
  const [filteredTesters, setFilteredTesters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedTesters, setSelectedTesters] = useState([]);
  const [confirmDialog, setConfirmDialog] = useState({ open: false, action: null, tester: null });
  // const [client, setClient] = useState(null);

  // useEffect(() => {
  //   async function fetchAwsConfig() {
  //     const awsconfig = await getAwsConfig();
  //     const client = new DynamoDBClient(awsconfig);
  //     setClient(client);
  //   }

  //   fetchAwsConfig();
  // }, []);

  const fetchTesterData = useCallback(async () => {
    if (!client) return; // Ensure client is available

    setLoading(true);
    try {
      const testerData = await fetchPendingTesters();
      const parsedData = parsePendingTesters(testerData);
      setPendingTesters(parsedData);
      setFilteredTesters(parsedData);
    } catch (err) {
      console.error("Error fetching pending tester data:", err);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchTesterData();
  }, [fetchTesterData]);

  useEffect(() => {
    const filtered = pendingTesters.filter((tester) => {
      const lowercasedQuery = searchQuery.toLowerCase();
      return (
        tester.firstName.toLowerCase().includes(lowercasedQuery) ||
        tester.lastName.toLowerCase().includes(lowercasedQuery) ||
        tester.email.toLowerCase().includes(lowercasedQuery)
      );
    });
    setFilteredTesters(filtered);
  }, [searchQuery, pendingTesters]);

  const fetchPendingTesters = async () => {
    if (!client) return; // Ensure client is available

    try {
      const input = {
        TableName: "users",
        FilterExpression: "isTester = :pending",
        ExpressionAttributeValues: {
          ":pending": { S: "pending" }
        }
      };
      const command = new ScanCommand(input);
      const data = await client.send(command);
      return data.Items;
    } catch (err) {
      console.error("Error scanning items:", err);
      throw err;
    }
  };
  
  const updateUserTesterStatus = async (email, approved) => {
    if (!client) return; // Ensure client is available
  
    try {
      const params = {
        TableName: "users",
        Key: {
          email: { S: email },
          id: { S: email }
        },
        UpdateExpression: "SET isTester = :testerStatus",
        ExpressionAttributeValues: {
          ":testerStatus": { S: approved ? "true" : "false" }
        }
      };
  
      const command = new UpdateItemCommand(params);
      await client.send(command);
  
      if (approved) {
        await sendEmail(email, "Spencer-Approved");
      } else {
        await sendEmail(email, "Seed-Tester-Rejection-Latest");
      }
      console.log("User tester status updated successfully");
    } catch (err) {
      console.error("Error updating user tester status:", err);
    }
  };
  
  const sendEmail = async (email, templateName) => {
    try {
      const sesTempRes = await getEmailTemplate(templateName);
      const { HtmlPart, SubjectPart } = sesTempRes.Template;
  
      let emailBodyHtml = HtmlPart;
      let emailBodyHtml1 = emailBodyHtml;
  
      const emailBodyText = emailBodyHtml1.replace(/<\/?[^>]+(>|$)/g, ""); // Strip HTML tags for text part
  
      const emailParams = {
        Source: "hg@homegrown-genetics.com",
        Destination: {
          ToAddresses: [email],
        },
        Message: {
          Subject: {
            Data: SubjectPart,
            Charset: "UTF-8",
          },
          Body: {
            Html: {
              Data: emailBodyHtml1,
              Charset: "UTF-8",
            },
            Text: {
              Data: emailBodyText,
              Charset: "UTF-8",
            },
          },
        },
      };
  
      const sesCommand = new SendEmailCommand(emailParams);
      await sesClient.send(sesCommand);
    } catch (err) {
      console.error("Error sending email:", err);
    }
  };
  
  const parsePendingTesters = (items) => {
    if (!client) return; // Ensure client is available

    return items.map((user) => ({
      id: user.id?.S || '',
      email: user.email?.S || '',
      firstName: user.firstName?.S || '',
      lastName: user.lastName?.S || '',
      instagram_username: user.instagram_username?.S || '',
      tshirtSize: user.tshirtSize?.S || '',
    }));
  };

  const handleSelectTester = (testerId) => {
    setSelectedTesters((prev) => 
      prev.includes(testerId) ? prev.filter(id => id !== testerId) : [...prev, testerId]
    );
  };

  const handleConfirmAction = async (approved) => {
    setLoading(true);
    if (confirmDialog.tester) {
      await updateUserTesterStatus(confirmDialog.tester.email, approved);
    } else {
      await Promise.all(selectedTesters.map(async (testerId) => {
        const tester = pendingTesters.find((t) => t.id === testerId);
        if (tester) {
          await updateUserTesterStatus(tester.email, approved);
        }
      }));
    }
    await fetchTesterData();
    setLoading(false);
    setSelectedTesters([]);
    setConfirmDialog({ open: false, action: null, tester: null });
  };

  const handleOpenConfirmDialog = (action, tester = null) => {
    setConfirmDialog({ open: true, action, tester });
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialog({ open: false, action: null, tester: null });
  };

  const isUserSelected = (testerId) => selectedTesters.includes(testerId);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ maxWidth: '100%', margin: 'auto', p: 3 }}>
      <Typography variant={isMobile ? 'h5' : 'h3'} gutterBottom sx={{ fontWeight: 'bold', color: 'primary.main', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', textTransform: 'uppercase', letterSpacing: 4, textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)' }}>
        <TesterIcon sx={{ mr: 2 }} /> Pending Testers
      </Typography>
      <Fade in={true}>
        <Paper elevation={8} sx={{ p: 3, borderRadius: 4, backgroundColor: 'background.paper', backgroundImage: 'linear-gradient(45deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.05))', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)' }}>
          <StyledSearchBar>
            <Search sx={{ mr: 2, color: 'text.secondary', fontSize: 28 }} />
            <StyledSearchInput
              placeholder="Search testers..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Tooltip title="Refresh" TransitionComponent={Zoom} arrow>
              <IconButton onClick={fetchTesterData} disabled={loading} sx={{ ml: 1, transition: 'transform 0.3s ease-in-out', '&:hover': { transform: 'rotate(360deg)' } }}>
                <Refresh sx={{ fontSize: 28 }} />
              </IconButton>
            </Tooltip>
          </StyledSearchBar>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox" />
                  <TableCell sx={{ fontWeight: 'bold', fontSize: 18 }}>First Name</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', fontSize: 18 }}>Last Name</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', fontSize: 18 }}>Email</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', fontSize: 18 }}>Instagram</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', fontSize: 18 }}>T-Shirt Size</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', fontSize: 18 }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <AnimatedTableBody>
                <AnimatePresence>
                  {filteredTesters.map((tester, index) => {
                    const isSelected = isUserSelected(tester.id);

                    return (
                      <AnimatedTableRow
                        key={tester.id}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.4, delay: index * 0.1, type: 'spring', stiffness: 300, damping: 20 }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isSelected}
                            onChange={() => handleSelectTester(tester.id)}
                            icon={<PersonAdd />}
                            checkedIcon={<PersonAdd />}
                          />
                        </TableCell>
                        <TableCell sx={{ fontSize: 16 }}>{tester.firstName}</TableCell>
                        <TableCell sx={{ fontSize: 16 }}>{tester.lastName}</TableCell>
                        <TableCell sx={{ fontSize: 16 }}>{tester.email}</TableCell>
                        <TableCell sx={{ fontSize: 16 }}>{tester.instagram_username}</TableCell>
                        <TableCell sx={{ fontSize: 16 }}>{tester.tshirtSize}</TableCell>
                        <TableCell>
                          <Tooltip title="Approve Tester" TransitionComponent={Zoom} arrow>
                            <Button
                              variant="contained"
                              color="success"
                              size={isMobile ? 'small' : 'medium'}
                              onClick={() => handleOpenConfirmDialog('approve', tester)}
                              sx={{ mr: 1, textTransform: 'uppercase', fontWeight: 'bold', transition: 'transform 0.3s ease-in-out', '&:hover': { transform: 'scale(1.05)' } }}
                            >
                              <ThumbUp sx={{ mr: isMobile ? 0 : 1 }} /> {isMobile ? '' : 'Approve'}
                            </Button>
                          </Tooltip>
                          <Tooltip title="Reject Tester" TransitionComponent={Zoom} arrow>
                            <Button
                              variant="contained"
                              color="error"
                              size={isMobile ? 'small' : 'medium'}
                              onClick={() => handleOpenConfirmDialog('reject', tester)}
                              sx={{ textTransform: 'uppercase', fontWeight: 'bold', transition: 'transform 0.3s ease-in-out', '&:hover': { transform: 'scale(1.05)' } }}
                            >
                              <ThumbDown sx={{ mr: isMobile ? 0 : 1 }} /> {isMobile ? '' : 'Reject'}
                            </Button>
                          </Tooltip>
                        </TableCell>
                      </AnimatedTableRow>
                    );
                  })}
                </AnimatePresence>
              </AnimatedTableBody>
            </Table>
          </TableContainer>
          <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center', justifyContent: 'space-between', mt: 3 }}>
            <Typography variant="h6" color="text.secondary" sx={{ mb: isMobile ? 2 : 0 }}>
              {selectedTesters.length} tester{selectedTesters.length !== 1 ? 's' : ''} selected
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
              <Tooltip title="Approve Selected Testers" TransitionComponent={Zoom} arrow>
                <span>
                  <Button
                    variant="contained"
                    color="success"
                    size={isMobile ? 'small' : 'large'}
                    onClick={() => handleOpenConfirmDialog('approve')}
                    disabled={loading || selectedTesters.length === 0}
                    startIcon={loading ? <CircularProgress size={20} /> : <ThumbUp />}
                    sx={{ mr: isMobile ? 0 : 2, mb: isMobile ? 2 : 0, textTransform: 'uppercase', fontWeight: 'bold', transition: 'transform 0.3s ease-in-out', '&:hover': { transform: 'scale(1.05)' } }}
                  >
                    Approve Selected
                  </Button>
                </span>
              </Tooltip>
              <Tooltip title="Reject Selected Testers" TransitionComponent={Zoom} arrow>
                <span>
                  <Button
                    variant="contained"
                    color="error"
                    size={isMobile ? 'small' : 'large'}
                    onClick={() => handleOpenConfirmDialog('reject')}
                    disabled={loading || selectedTesters.length === 0}
                    startIcon={loading ? <CircularProgress size={20} /> : <ThumbDown />}
                    sx={{ textTransform: 'uppercase', fontWeight: 'bold', transition: 'transform 0.3s ease-in-out', '&:hover': { transform: 'scale(1.05)' } }}
                  >
                    Reject Selected
                  </Button>
                </span>
              </Tooltip>
            </Box>
          </Box>
        </Paper>
      </Fade>
      <Fade in={pendingTesters.length === 0}>
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <TesterIcon />
          <Typography variant={isMobile ? 'h6' : 'h4'} color="text.secondary" sx={{ mt: 2 }}>
            No pending tester requests
          </Typography>
        </Box>
      </Fade>

      <ConfirmationDialog
        open={confirmDialog.open}
        onClose={handleCloseConfirmDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Zoom}
        transitionDuration={500}
      >
        <DialogTitle id="alert-dialog-title">
          {`Confirm ${confirmDialog.action === 'approve' ? 'Approval' : 'Rejection'}`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ fontSize: '1.2rem' }}>
            {confirmDialog.tester
              ? `Are you sure you want to ${confirmDialog.action} ${confirmDialog.tester.firstName} ${confirmDialog.tester.lastName} as a tester?`
              : `Are you sure you want to ${confirmDialog.action} the selected testers?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseConfirmDialog}
            color="primary"
            variant="contained"
            size="large"
            sx={{ width: '120px' }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleConfirmAction(confirmDialog.action === 'approve')}
            color={confirmDialog.action === 'approve' ? 'success' : 'error'}
            variant="contained"
            size="large"
            autoFocus
            startIcon={confirmDialog.action === 'approve' ? <ThumbUp /> : <ThumbDown />}
            sx={{ width: '120px' }}
          >
            {confirmDialog.action === 'approve' ? 'Approve' : 'Reject'}
          </Button>
        </DialogActions>
      </ConfirmationDialog>
    </Box>
  );
};

export default PendingTestersTable;
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Paper, List, ListItem, ListItemText } from '@mui/material';
import { DynamoDBClient, ScanCommand } from '@aws-sdk/client-dynamodb';
import { parseOrders } from '../../utils/dynamo/parsers';

// const client = new DynamoDBClient(awsconfig);

import getAwsConfig from "../../awsconfig";
const awsconfig = await getAwsConfig();
const client = new DynamoDBClient(awsconfig);

const fetchOrder = async (orderId) => {
  try {
    const params = {
      TableName: 'orders',
      FilterExpression: 'id = :id',
      ExpressionAttributeValues: {
        ':id': { S: orderId },
      },
    };
    const command = new ScanCommand(params);
    const data = await client.send(command);
    const orders = data.Items;
    return orders.length > 0 ? orders[0] : null;
  } catch (err) {
    console.error('Error fetching order:', err);
    throw err;
  }
};

const formatItems = (jsonString) => {
  // Parse the JSON string
  const items = JSON.parse(jsonString);

  // Map each item to a Typography component
  return items.map((item, index) => (
    <Typography variant="h6" key={index}>
      ID: {item.id}, Title: {item.title}, Price: {item.price}, Quantity: {item.quantity}
    </Typography>
  ));
};

const OrderDetail = () => {
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);

  useEffect(() => {
    const getOrder = async () => {
      try {
        const order = await fetchOrder(orderId);
        console.log(order.basketData.S)
        console.log(order)
        setOrder(order)

      } catch (err) {
        console.error('Error:', err);
      }
    };

    getOrder();
  }, [orderId]);

  return order ? (
    <Box>
      <Typography variant="h4" gutterBottom>
        Order Detail
      </Typography>
      <Paper elevation={3} sx={{ p: 2 }}>
        <Typography variant="h6">Order ID: {order.id.S}</Typography>
        <Typography variant="body1">Email: {order.email.S}</Typography>
        <Typography variant="body1">Order Date: {new Date(order.modifyDate.S).toLocaleString()}</Typography>
        <Typography variant="body1">Billing Name: {order.billingFirstName.S} {order.billingLastName.S}</Typography>
        <Typography variant="body1">Billing Address: {order.billingAddress.S} {order.billingAddress2.S}, {order.billingCity.S} {order.billingState.S} {order.billingZip.S}</Typography>
        <Typography variant="body1">Shipping Name: {order.shippingFirstName.S} {order.shippingLastName.S}</Typography>
        <Typography variant="body1">Shipping Address: {order.shippingAddress.S} {order.shippingAddress2.S}, {order.shippingCity.S} {order.shippingState.S} {order.shippingZip.S}</Typography>
        <Typography variant="body1">Total: ${order.total.N}</Typography>
        <Box mt={2}>
          <Typography variant="h6">Items</Typography>
          {/* <List>
            {order.basketData.S.map((item, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={`${item.title} (Quantity: ${item.quantity})`}
                  secondary={`Price: $${item.price.toFixed(2)}`}
                />
              </ListItem>
            ))}
          </List> */}
          <div>
          <Typography variant="h6">{formatItems(order.basketData.S)}</Typography>
          </div>
        </Box>
      </Paper>
    </Box>
  ) : (
    <Typography variant="h6">Loading order details...</Typography>
  );
};

export default OrderDetail;

import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Container, Typography } from "@mui/material";
import { DynamoDBClient, ScanCommand } from "@aws-sdk/client-dynamodb";
import ListingsGrid from './ListingsGrid';

import getAwsConfig from "../../awsconfig";
const awsconfig = await getAwsConfig();
const client = new DynamoDBClient(awsconfig);

const ListingsPage = () => {
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchListings();
  }, []);

  const fetchListings = async () => {
    try {
      const command = new ScanCommand({
        TableName: "allSeeds",
      });
      const { Items } = await client.send(command);
      const formattedItems = Items.map(item => ({
        id: item.id.S,
        name: item.name.S,
        price: item.price.N,
        description: item.description.S,
        imageUrl: item.image_url.S,
        isActive: item.isActive.BOOL,
        collection: item.collection.S,
        testPdf: item.test_pdf.S,
        createdDate: item.createdDate.S,
        // isSoldOut: item.isSoldOut.BOOL
        // isFeatured: item.isFeatured.BOOL,
        // isNewArrival: item.isNewArrival.BOOL,
        // isOnSale: item.isOnSale.BOOL,
      }));
      setListings(formattedItems);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching listings:", error);
      setLoading(false);
    }
  };

  const handleListingClick = (id, createdDate) => {
    navigate(`/listings/edit/${id}/${createdDate}`);
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 8 }}>
      <Typography variant="h3" align="center" gutterBottom>
        All Listings
      </Typography>
      <ListingsGrid 
        listings={listings} 
        loading={loading} 
        onListingClick={handleListingClick}
      />
    </Container>
  );
};

export default ListingsPage;
import React, { useState, useEffect } from 'react';
import { Typography, Box, Grid, Card, CardContent, CardHeader, Avatar, Container, useMediaQuery, useTheme } from '@mui/material';
import { People, ShoppingCart, PersonAdd, Agriculture } from '@mui/icons-material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { styled } from '@mui/material/styles';
import { DynamoDBClient, ScanCommand } from "@aws-sdk/client-dynamodb";
import { unmarshall } from "@aws-sdk/util-dynamodb";

import UserTable from '../users/UserTable';
import OrderTable from '../orders/OrderTable';
import PendingTestersTable from '../tester/PendingTestersTable';
import SeedRequestsTable from '../pending/SeedRequestsTable';

import getAwsConfig from "../../awsconfig";
const awsconfig = await getAwsConfig();
const client = new DynamoDBClient(awsconfig);

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: '0.3s',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[4],
  },
}));

function Dashboard() {
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [pendingTesters, setPendingTesters] = useState(0);
  const [seedRequests, setSeedRequests] = useState(0);
  const [totalMade, setTotalMade] = useState(0);
  const [totalMadeProfit, setTotalMadeProfit] = useState(0);
  const [totalLasariaCost, setTotalLasariaCost] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  // const [client, setClient] = useState(null);

  // useEffect(() => {
  //   async function fetchAwsConfig() {
  //     const awsconfig = await getAwsConfig();
  //     const client = new DynamoDBClient(awsconfig);
  //     setClient(client);
  //   }

  //   fetchAwsConfig();
  // }, []);

  useEffect(() => {
    async function fetchData() {
      if (!client) return; 

      try {
        const usersCommand = new ScanCommand({
          TableName: "users",
          Select: "COUNT"
        });
        const usersResult = await client.send(usersCommand);
        setTotalUsers(usersResult.Count);

        const ordersCommand = new ScanCommand({
          TableName: "orders",
          Select: "COUNT"
        });
        const ordersResult = await client.send(ordersCommand);
        setTotalOrders(ordersResult.Count);

        const pendingTestersCommand = new ScanCommand({
          TableName: "users",
          FilterExpression: "isTester = :pending",
          ExpressionAttributeValues: {
            ":pending": { S: "pending" }
          },
          Select: "COUNT"
        });
        const pendingTestersResult = await client.send(pendingTestersCommand);
        setPendingTesters(pendingTestersResult.Count);

        const seedRequestsCommand = new ScanCommand({
          TableName: "users",
          FilterExpression: "requestedSeeds = :true",
          ExpressionAttributeValues: {
            ":true": { BOOL: true }
          },
          Select: "COUNT"
        });
        const seedRequestsResult = await client.send(seedRequestsCommand);
        setSeedRequests(seedRequestsResult.Count);

        var totalSum = 0;
        var lastEvaluatedKey = null;
        const params = {
          TableName: "orders",
          ProjectionExpression: "#t", 
          ExpressionAttributeNames: {
            "#t": "total"
          }
        };

          do {
            if (lastEvaluatedKey) {
              params.ExclusiveStartKey = lastEvaluatedKey;
            }

            const command = new ScanCommand(params);
            const response = await client.send(command);
            console.log(response);

            const items = response.Items.map((item) => unmarshall(item));
            totalSum += items.reduce((sum, item) => sum + item.total, 0);
            
            
            lastEvaluatedKey = response.LastEvaluatedKey;
          } while (lastEvaluatedKey);
          setTotalMade(parseFloat(totalSum))
          console.log("total made: " + totalMade)

        var totalSumProfit = 0;
        var lastEvaluatedKeyProfit = null;
        const paramsProfit = {
          TableName: "Profits",
          ProjectionExpression: "#p", 
          ExpressionAttributeNames: {
            "#p": "profit"
          }
        };

          do {
            if (lastEvaluatedKeyProfit) {
              paramsProfit.ExclusiveStartKey = lastEvaluatedKeyProfit;
            }

            const commandProfit = new ScanCommand(paramsProfit);
            const responseProfit = await client.send(commandProfit);
            console.log(responseProfit);

            const itemsProfit = responseProfit.Items.map((item) => unmarshall(item));
            const batchSum = itemsProfit.reduce((sum, item) => {
              const parsedProfit = parseFloat(parseFloat(item.profit).toFixed(2));
              return sum + parsedProfit;
            }, 0);

            totalSumProfit = parseFloat((totalSumProfit + batchSum).toFixed(2));

            console.log("Batch Profit Sum: " + batchSum.toFixed(2));
            console.log("Running Total Profit: " + totalSumProfit.toFixed(2));
            
            
            lastEvaluatedKeyProfit = responseProfit.LastEvaluatedKey;
          } while (lastEvaluatedKeyProfit);
          setTotalMadeProfit(parseFloat(totalSumProfit))
          setTotalLasariaCost(parseFloat(totalSumProfit * 0.04).toFixed(2))
          console.log("total made: " + totalMadeProfit)

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [client]); 

  return (
    <Container maxWidth="100%" disableGutters>
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant={isMobile ? "h5" : "h4"} gutterBottom align="center">
          Dashboard
        </Typography>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
            <StyledCard>
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: 'primary.main' }}>
                    <People />
                  </Avatar>
                }
                title="Total Users"
              />
              <CardContent>
                <Typography variant="h4">{totalUsers}</Typography>
              </CardContent>
            </StyledCard>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
            <StyledCard>
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: 'secondary.main' }}>
                    <ShoppingCart />
                  </Avatar>
                }
                title="Total Orders"
              />
              <CardContent>
                <Typography variant="h4">{totalOrders}</Typography>
              </CardContent>
            </StyledCard>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
            <StyledCard>
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: '#b59326' }}>
                    <PersonAdd />
                  </Avatar>
                }
                title="Pending Testers"
              />
              <CardContent>
                <Typography variant="h4">{pendingTesters}</Typography>
              </CardContent>
            </StyledCard>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
            <StyledCard>
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: 'warning.main' }}>
                    <Agriculture />
                  </Avatar>
                }
                title="Seed Requests"
              />
              <CardContent>
                <Typography variant="h4">{seedRequests}</Typography>
              </CardContent>
            </StyledCard>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
            <StyledCard>
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: 'green' }}>
                    <AttachMoneyIcon />
                  </Avatar>
                }
                title="Total Made"
              />
              <CardContent>
                <Typography variant="h4">${totalMade}</Typography>
              </CardContent>
            </StyledCard>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
            <StyledCard>
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: 'limeGreen' }}>
                    <AttachMoneyIcon />
                  </Avatar>
                }
                title="Total Profit"
              />
              <CardContent>
                <Typography variant="h4">${totalMadeProfit}</Typography>
              </CardContent>
            </StyledCard>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
            <StyledCard>
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: 'magenta' }}>
                    <AttachMoneyIcon />
                  </Avatar>
                }
                title="Total Lasaria Cost"
              />
              <CardContent>
                <Typography variant="h4">${totalLasariaCost}</Typography>
              </CardContent>
            </StyledCard>
          </Grid>
        </Grid>


        <Box mt={4}>
          <UserTable limit={isMobile ? 3 : 5} />
        </Box>

        <Box mt={4}>
          <OrderTable limit={isMobile ? 3 : 5} />
        </Box>

        <Box mt={4}>
          <PendingTestersTable limit={isMobile ? 3 : 5} />
        </Box>

        <Box mt={4}>
          <SeedRequestsTable limit={isMobile ? 3 : 5} />
        </Box>
      </Box>
    </Container>
  );
}

export default Dashboard;

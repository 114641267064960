import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography, Switch, TextField, Button, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Tooltip, Zoom, TableSortLabel, useMediaQuery, useTheme } from '@mui/material';
import { Check, LocalShipping, ShoppingCart, ArrowUpward, ArrowDownward } from '@mui/icons-material';
import { DynamoDBClient, ScanCommand, UpdateItemCommand } from "@aws-sdk/client-dynamodb";
import { styled, keyframes } from '@mui/system';
import { Checkmark } from 'react-checkmark';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { Hidden } from '@mui/material';
import getAwsConfig from "../../awsconfig";

const awsconfig = await getAwsConfig();
const client = new DynamoDBClient(awsconfig);

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  width: '100%',
}));

const ResponsiveTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(2),
  },
}));

const ResponsiveText = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '0.875rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1rem',
  },
}));

const AnimatedTableRow = styled(motion.tr)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    transform: 'scale(1.01)',
    boxShadow: theme.shadows[4],
  },
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.selectedOpacity,
  },
}));

const AnimatedTableBody = styled(TableBody)({
  '& > tr': {
    animation: `${fadeIn} 0.5s ease-in-out`,
    opacity: 0,
  },
});

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-input': {
    padding: theme.spacing(1),
    fontSize: '1rem',
    transition: 'background-color 0.3s ease-in-out',
    '&:focus': {
      backgroundColor: theme.palette.action.focus,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.75rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
}));

const NotApplicable = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
  backgroundColor: theme.palette.action.disabledBackground,
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.text.disabled,
  fontStyle: 'italic',
  fontSize: '1rem',
}));

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    fractionalSecondDigits: 2,
    timeZoneName: 'short'
  };
  return new Intl.DateTimeFormat('en-US', options).format(date);
};

const OrderTable = ({ fulfilled }) => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [localEdits, setLocalEdits] = useState({});
  const [loading, setLoading] = useState(false);
  const [showCheckmark, setShowCheckmark] = useState(false);
  const [sortDirection, setSortDirection] = useState('desc');
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();
  
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('xl'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('md', 'xl'));

  const sortOrders = (orders) => {
    if (!client) return;

    return orders.sort((a, b) => {
      if (sortDirection === 'asc') {
        return new Date(a.createDate) - new Date(b.createDate);
      } else {
        return new Date(b.createDate) - new Date(a.createDate);
      }
    });
  };

  const fetchOrders = async () => {
    if (!client) return;

    try {
      const input = {
        TableName: "orders",
      };
      const command = new ScanCommand(input);
      const data = await client.send(command);
      return data.Items;
    } catch (err) {
      console.error("Error scanning items:", err);
      throw err;
    }
  };
  
  const updateOrderField = async (email, createDate, field, value) => {
    if (!client) return;

    try {
      let attributeValue;
      if (typeof value === 'boolean') {
        attributeValue = { BOOL: value };
      } else if (!isNaN(value)) {
        attributeValue = { N: value.toString() };
      } else {
        attributeValue = { S: value.toString() };
      }
  
      const params = {
        TableName: "orders",
        Key: {
          email: { S: email },
          createDate: { S: createDate }
        },
        UpdateExpression: `SET #field = :value`,
        ExpressionAttributeNames: {
          "#field": field
        },
        ExpressionAttributeValues: {
          ":value": attributeValue
        }
      };
  
      const command = new UpdateItemCommand(params);
      await client.send(command);
    } catch (err) {
      console.error("Error updating order field:", err);
    }
  };
  
  const parseOrders = (items) => {
    return items.map((item) => ({
      id: item.id?.S || '',
      email: item.email?.S || '',
      createDate: item.createDate?.S || '',
      billingFirstName: item.billingFirstName?.S || '',
      billingLastName: item.billingLastName?.S || '',
      total: parseFloat(item.total?.N) || 0,
      isMerchandiseFulfilled: item.isMerchandiseFulfilled?.BOOL || false,
      isOtherFulfilled: item.isOtherFulfilled?.BOOL || false,
      merchandiseTrackingNumber: item.merchandiseTrackingNumber?.S || '',
      otherTrackingNumber: item.otherTrackingNumber?.N || '',
      hasMerchandise: (item.merchandise.L).length > 0,
      hasOtherProducts: (item.otherProducts.L).length > 0,
      adminNotes: item.adminNotes?.S || ''
    }));
  };

  const handleSortChange = () => {
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0); 
  };

  const paginatedOrders = filteredOrders.slice(
    currentPage * rowsPerPage,
    currentPage * rowsPerPage + rowsPerPage
  );

  const fetchOrderData = async () => {
    if (!client) return;

    try {
      const orderData = await fetchOrders();
      const parsedOrders = parseOrders(orderData);
      setOrders(parsedOrders);
    } catch (err) {
      console.error("Error fetching order data:", err);
    }
  };

  useEffect(() => {
    fetchOrderData();
  }, []);

  useEffect(() => {
    let filteredOrders;

    if (fulfilled === true) {
      filteredOrders = orders.filter(order => {
        if (order.hasMerchandise && !order.hasOtherProducts) {
          return order.isMerchandiseFulfilled;
        } else if (!order.hasMerchandise && order.hasOtherProducts) {
          return order.isOtherFulfilled;
        } else if (order.hasMerchandise && order.hasOtherProducts) {
          return order.isMerchandiseFulfilled && order.isOtherFulfilled;
        } else {
          return false;
        }
      });
    } else if (fulfilled === false) {
      filteredOrders = orders.filter(order => {
        if (order.hasMerchandise && !order.hasOtherProducts) {
          return !order.isMerchandiseFulfilled;
        } else if (!order.hasMerchandise && order.hasOtherProducts) {
          return !order.isOtherFulfilled;
        } else if (order.hasMerchandise && order.hasOtherProducts) {
          return !order.isMerchandiseFulfilled || !order.isOtherFulfilled;
        } else {
          return true;
        }
      });
    } else {
      filteredOrders = orders;
    }
    setFilteredOrders(sortOrders(filteredOrders));
  }, [client, orders, fulfilled, sortDirection]);

  const handleRowClick = (orderId, event) => {
    const tagName = event.target.tagName.toLowerCase();
    const className = event.target.className;
    if (tagName === 'input' || tagName === 'button' || className.includes('MuiSwitch-root') || className.includes('MuiTextField-root')) {
      return;
    }
    navigate(`/orders/${orderId}`);
  };

  const handleToggle = (email, createDate, event, field) => {
    if (!client) return;

    const newValue = event.target.checked;
    setLocalEdits((prev) => ({
      ...prev,
      [email]: {
        ...prev[email],
        [createDate]: {
          ...prev[email]?.[createDate],
          [field]: newValue,
        }
      }
    }));
  };

  const handleTrackingChange = (email, createDate, event, field) => {
    if (!client) return;

    const newValue = event.target.value;
    setLocalEdits((prev) => ({
      ...prev,
      [email]: {
        ...prev[email],
        [createDate]: {
          ...prev[email]?.[createDate],
          [field]: newValue,
        }
      }
    }));
  };

  const handleSaveChanges = async () => {
    if (!client) return;

    setLoading(true);
    const updates = Object.entries(localEdits).flatMap(([email, dates]) =>
      Object.entries(dates).flatMap(([createDate, fields]) =>
        Object.entries(fields).map(([field, value]) => ({ email, createDate, field, value }))
      )
    );
    await Promise.all(updates.map(update => updateOrderField(update.email, update.createDate, update.field, update.value)));
    setLoading(false);
    setShowCheckmark(true);
    setTimeout(() => {
      setShowCheckmark(false);
    }, 2000);
    setLocalEdits({});
    fetchOrderData();
  };

  return (
    <Box sx={{ maxWidth: '100%', margin: 'auto', p: 3 }}>
      <Typography 
        variant={isLargeScreen ? 'h3' : 'h4'} 
        gutterBottom 
        sx={{ 
          fontWeight: 'bold', 
          color: 'primary.main', 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center', 
          textAlign: 'center', 
          textTransform: 'uppercase', 
          letterSpacing: 4, 
          textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)' 
        }}
      >
        <ShoppingCart sx={{ mr: 2 }} /> Recent Orders
      </Typography>
      <Paper elevation={8} sx={{ p: 3, borderRadius: 4, backgroundColor: 'background.paper', backgroundImage: 'linear-gradient(45deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.05))', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)' }}>
        <StyledTableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <ResponsiveTableCell>
                  <ResponsiveText>Order ID</ResponsiveText>
                </ResponsiveTableCell>
                <Hidden smDown>
                  <ResponsiveTableCell>
                    <ResponsiveText>Email</ResponsiveText>
                  </ResponsiveTableCell>
                </Hidden>
                <ResponsiveTableCell>
                  <TableSortLabel
                    active={true}
                    direction={sortDirection}
                    onClick={handleSortChange}
                    IconComponent={sortDirection === 'asc' ? ArrowUpward : ArrowDownward}
                  >
                    <ResponsiveText>Create Date</ResponsiveText>
                  </TableSortLabel>
                </ResponsiveTableCell>
                <Hidden mdDown>
                  <ResponsiveTableCell>
                    <ResponsiveText>Billing Name</ResponsiveText>
                  </ResponsiveTableCell>
                </Hidden>
                <ResponsiveTableCell>
                  <ResponsiveText>Total</ResponsiveText>
                </ResponsiveTableCell>
                <ResponsiveTableCell>
                  <ResponsiveText>Fulfilled</ResponsiveText>
                </ResponsiveTableCell>
                <Hidden smDown>
                  <ResponsiveTableCell>
                    <ResponsiveText>Tracking</ResponsiveText>
                  </ResponsiveTableCell>
                </Hidden>
                <Hidden mdDown>
                  <ResponsiveTableCell>
                    <ResponsiveText>Notes</ResponsiveText>
                  </ResponsiveTableCell>
                </Hidden>
              </TableRow>
            </TableHead>
            <TableBody>
              <AnimatePresence>
                {paginatedOrders.map((order, index) => (
                  <motion.tr
                    key={`${order.email}-${order.createDate}`}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.4, delay: index * 0.1, type: 'spring', stiffness: 300, damping: 20 }}
                    onClick={(event) => handleRowClick(order.id, event)}
                  >
                    <ResponsiveTableCell>
                      <ResponsiveText>{order.id}</ResponsiveText>
                    </ResponsiveTableCell>
                    <Hidden smDown>
                      <ResponsiveTableCell>
                        <ResponsiveText>{order.email}</ResponsiveText>
                      </ResponsiveTableCell>
                    </Hidden>
                    <ResponsiveTableCell>
                      <ResponsiveText>{formatDate(order.createDate)}</ResponsiveText>
                    </ResponsiveTableCell>
                    <Hidden mdDown>
                      <ResponsiveTableCell>
                        <ResponsiveText>{`${order.billingFirstName} ${order.billingLastName}`}</ResponsiveText>
                      </ResponsiveTableCell>
                    </Hidden>
                    <ResponsiveTableCell>
                      <ResponsiveText>${order.total.toFixed(2)}</ResponsiveText>
                    </ResponsiveTableCell>
                    <ResponsiveTableCell>
                      <Box display="flex" flexDirection="column" alignItems="center">
                        {order.hasMerchandise && (
                          <Tooltip title="Toggle Merchandise Fulfillment" TransitionComponent={Zoom} arrow>
                            <Switch
                              checked={localEdits[order.email]?.[order.createDate]?.isMerchandiseFulfilled ?? order.isMerchandiseFulfilled}
                              onChange={(event) => handleToggle(order.email, order.createDate, event, 'isMerchandiseFulfilled')}
                              color="primary"
                              size="small"
                            />
                          </Tooltip>
                        )}
                        {order.hasOtherProducts && (
                          <Tooltip title="Toggle Seeds Fulfillment" TransitionComponent={Zoom} arrow>
                            <Switch
                              checked={localEdits[order.email]?.[order.createDate]?.isOtherFulfilled ?? order.isOtherFulfilled}
                              onChange={(event) => handleToggle(order.email, order.createDate, event, 'isOtherFulfilled')}
                              color="primary"
                              size="small"
                            />
                          </Tooltip>
                        )}
                      </Box>
                    </ResponsiveTableCell>
                    <Hidden smDown>
                      <ResponsiveTableCell>
                        <Box display="flex" flexDirection="column">
                          {order.hasMerchandise && (
                            <StyledTextField
                              type="text"
                              value={localEdits[order.email]?.[order.createDate]?.merchandiseTrackingNumber ?? order.merchandiseTrackingNumber}
                              onChange={(event) => handleTrackingChange(order.email, order.createDate, event, 'merchandiseTrackingNumber')}
                              fullWidth
                              placeholder="Merch tracking"
                              size="small"
                              InputProps={{
                                startAdornment: <LocalShipping color="action" sx={{ mr: 1, fontSize: 16 }} />,
                              }}
                            />
                          )}
                          {order.hasOtherProducts && (
                            <StyledTextField
                              type="text"
                              value={localEdits[order.email]?.[order.createDate]?.otherTrackingNumber ?? order.otherTrackingNumber}
                              onChange={(event) => handleTrackingChange(order.email, order.createDate, event, 'otherTrackingNumber')}
                              fullWidth
                              placeholder="Seeds tracking"
                              size="small"
                              InputProps={{
                                startAdornment: <LocalShipping color="action" sx={{ mr: 1, fontSize: 16 }} />,
                              }}
                            />
                          )}
                        </Box>
                      </ResponsiveTableCell>
                    </Hidden>
                    <Hidden mdDown>
                      <ResponsiveTableCell>
                        <StyledTextField
                          type="text"
                          value={localEdits[order.email]?.[order.createDate]?.adminNotes ?? order.adminNotes}
                          onChange={(event) => handleTrackingChange(order.email, order.createDate, event, 'adminNotes')}
                          fullWidth
                          placeholder="Enter notes"
                          size="small"
                        />
                      </ResponsiveTableCell>
                    </Hidden>
                  </motion.tr>
                ))}
              </AnimatePresence>
            </TableBody>
          </Table>
        </StyledTableContainer>
        <TablePagination
          component="div"
          count={filteredOrders.length}
          page={currentPage}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Orders per page"
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 3 }}>
          <Button
            variant="contained"
            color="primary"
            size={isLargeScreen ? 'large' : 'medium'}
            onClick={handleSaveChanges}
            disabled={loading}
            startIcon={loading ? <CircularProgress size={24} /> : <Check />}
            sx={{ fontWeight: 'bold', textTransform: 'uppercase', transition: 'transform 0.3s ease-in-out', '&:hover': { transform: 'scale(1.05)' } }}
          >
            {loading ? 'Saving...' : 'Save Changes'}
          </Button>
          {showCheckmark && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Checkmark size={isLargeScreen ? 'large' : 'medium'} color="#4caf50" />
              <Typography variant={isLargeScreen ? 'h6' : 'body1'} sx={{ ml: 1, color: '#4caf50', fontWeight: 'bold', textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)' }}>
                Changes Saved!
              </Typography>
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default OrderTable;

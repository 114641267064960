import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { 
  Drawer, 
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemText, 
  Collapse, 
  Avatar, 
  Typography, 
  Box, 
  IconButton 
} from '@mui/material';
import { 
  Dashboard, 
  People, 
  ListAlt, 
  Inventory, 
  Stars, 
  Settings, 
  Logout, 
  ExpandLess, 
  ExpandMore, 
  ChevronLeft, 
  ChevronRight 
} from '@mui/icons-material';
import { motion } from 'framer-motion';
import { useAuthenticator } from '@aws-amplify/ui-react';

const drawerWidth = 280;
const collapsedDrawerWidth = 60;

const StyledDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: open ? drawerWidth : collapsedDrawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    '& .MuiDrawer-paper': {
      width: open ? drawerWidth : collapsedDrawerWidth,
      background: '#2196F3',
      color: theme.palette.common.white,
      boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  })
);

const StyledListItem = styled(ListItem)(({ theme }) => ({
  margin: '4px 8px', 
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const StyledListItemIcon = styled(ListItemIcon)({
  color: 'inherit',
  minWidth: 40,
});

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  '& .MuiListItemText-primary': {
    fontWeight: 500,
    fontSize: '0.95rem',
  },
}));

const NestedListItem = styled(ListItem)(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  margin: '2px 8px', 
}));

const ProfileSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(3),
  marginBottom: theme.spacing(2),
}));

const MotionListItem = motion(StyledListItem);

const SidebarItem = ({ icon: Icon, primary, to, nested, onClick, open, expanded }) => (
  <MotionListItem
    button
    component="a"
    href={to}
    onClick={onClick}
    whileHover={{ scale: 1.03 }}
    whileTap={{ scale: 0.98 }}
  >
    <StyledListItemIcon>
      <Icon />
    </StyledListItemIcon>
    {open && (
      <>
        <StyledListItemText primary={primary} />
        {nested && (expanded ? <ExpandLess /> : <ExpandMore />)}
      </>
    )}
  </MotionListItem>
);

const Sidebar = () => {
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const [openUsers, setOpenUsers] = useState(false);
  const [openOrders, setOpenOrders] = useState(false);
  const [openListings, setOpenListings] = useState(false);
  const [open, setOpen] = useState(true);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  return (
    <StyledDrawer variant="permanent" open={open}>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-end', marginTop: "60px" }}>
          <IconButton onClick={handleDrawerToggle} sx={{ color: 'white' }}>
            {open ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </Box>
        <List sx={{ flexGrow: 1 }}>
          <SidebarItem icon={Dashboard} primary="Dashboard" to="/" open={open} />
          <SidebarItem
            icon={People}
            primary="Users"
            nested
            onClick={() => setOpenUsers(!openUsers)}
            open={open}
            expanded={openUsers}
          />
          {open && (
            <Collapse in={openUsers} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <NestedListItem button component="a" href="/users">
                  <StyledListItemText primary="All Users" />
                </NestedListItem>
                <NestedListItem button component="a" href="/users/pending">
                  <StyledListItemText primary="Pending Testers" />
                </NestedListItem>
                <NestedListItem button component="a" href="/users/seed-requests">
                  <StyledListItemText primary="Seed Requests" />
                </NestedListItem>
              </List>
            </Collapse>
          )}
          
          <SidebarItem
            icon={ListAlt}
            primary="Orders"
            nested
            onClick={() => setOpenOrders(!openOrders)}
            open={open}
            expanded={openOrders}
          />
          {open && (
            <Collapse in={openOrders} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <NestedListItem button component="a" href="/orders">
                  <StyledListItemText primary="All Orders" />
                </NestedListItem>
                <NestedListItem button component="a" href="/orders/fulfilled">
                  <StyledListItemText primary="Fulfilled Orders" />
                </NestedListItem>
                <NestedListItem button component="a" href="/orders/unfulfilled">
                  <StyledListItemText primary="Unfulfilled Orders" />
                </NestedListItem>
              </List>
            </Collapse>
          )}

          <SidebarItem
            icon={Inventory}
            primary="Listings"
            nested
            onClick={() => setOpenListings(!openListings)}
            open={open}
            expanded={openListings}
          />
          {open && (
            <Collapse in={openListings} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <NestedListItem button component="a" href="/listings">
                  <StyledListItemText primary="All Listings" />
                </NestedListItem>
                <NestedListItem button component="a" href="/listings/active">
                  <StyledListItemText primary="Active Listings" />
                </NestedListItem>
                <NestedListItem button component="a" href="/listings/inactive">
                  <StyledListItemText primary="Inactive Listings" />
                </NestedListItem>
                <NestedListItem button component="a" href="/create-listing">
                  <StyledListItemText primary="Create Seed Listing" />
                </NestedListItem>
              </List>
            </Collapse>
          )}

          <SidebarItem icon={Stars} primary="Rewards" to="/rewards" open={open} />
          <SidebarItem icon={Settings} primary="Settings" to="/settings" open={open} />
          <SidebarItem
            icon={Logout}
            primary="Logout"
            onClick={signOut}
            open={open}
          />
        </List>
      </Box>
    </StyledDrawer>
  );
};

export default Sidebar;
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { 
  Box, Paper, Typography, Switch, TextField, Button, CircularProgress, 
  IconButton, Table, TableBody, TableCell, TableContainer, TableHead, 
  TableRow, TablePagination, Checkbox, Tooltip, Zoom, useMediaQuery, 
  useTheme, Hidden
} from '@mui/material';
import { 
  ViewList, Add, Check, Instagram, Search, Refresh, Person, 
  ShoppingCart, Stars, LocalShipping
} from '@mui/icons-material';
import { DynamoDBClient, ScanCommand, UpdateItemCommand } from "@aws-sdk/client-dynamodb";
import getAwsConfig from "../../awsconfig";
import { styled, keyframes } from '@mui/system';
import { Checkmark } from 'react-checkmark';
import { motion, AnimatePresence } from 'framer-motion';

const AnimatedTableBody = styled(TableBody)({
  '& > tr': {
    opacity: 0,
  },
});

const PulseAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.6;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const UserTable = () => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('xl'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('md', 'xl'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    overflowX: 'auto',
    '& .MuiTable-root': {
      minWidth: 1000,
    },
    [theme.breakpoints.down('lg')]: {
      '& .MuiTable-root': {
        minWidth: 750,
      },
    },
  }));

  const ResponsiveTableCell = styled(TableCell)(({ theme }) => ({
    padding: theme.spacing(2),
    [theme.breakpoints.down('xl')]: {
      padding: theme.spacing(1.5),
    },
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(1),
    },
  }));

  const ResponsiveText = styled(Typography)(({ theme }) => ({
    fontSize: '1rem',
    [theme.breakpoints.down('xl')]: {
      fontSize: '0.9rem',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '0.8rem',
    },
  }));

  const StyledSearchBar = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[4],
    marginBottom: theme.spacing(3),
    transition: 'box-shadow 0.3s ease-in-out',
    '&:hover': {
      boxShadow: theme.shadows[8],
    },
  }));

  const StyledSearchInput = styled('input')(({ theme }) => ({
    flex: 1,
    border: 'none',
    outline: 'none',
    padding: theme.spacing(1),
    fontSize: '1rem',
    backgroundColor: 'transparent',
    transition: 'background-color 0.3s ease-in-out',
    '&:focus': {
      backgroundColor: theme.palette.action.focus,
    },
  }));

  const UserIcon = styled(Person)(({ theme }) => ({
    fontSize: 60,
    color: theme.palette.primary.main,
    animation: `${PulseAnimation} 2s ease-in-out infinite`,
  }));

  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editedUsers, setEditedUsers] = useState({});
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [rewardPoints, setRewardPoints] = useState(0);
  const [showCheckmark, setShowCheckmark] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [client, setClient] = useState(null);

  useEffect(() => {
    async function fetchAwsConfig() {
      const awsconfig = await getAwsConfig();
      const client = new DynamoDBClient(awsconfig);
      setClient(client);
    }

    fetchAwsConfig();
  }, []);

  const fetchUserData = async () => {
    if (!client) return;

    try {
      const userData = await fetchUsers();
      const parsedData = parseUsers(userData);
      setUsers(parsedData);
      setFilteredUsers(parsedData);
    } catch (err) {
      console.error("Error fetching user data:", err);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [client]);

  useEffect(() => {
    const filtered = users.filter((user) => {
      const lowercasedQuery = searchQuery.toLowerCase();
      return (
        user.firstName.toLowerCase().includes(lowercasedQuery) ||
        user.lastName.toLowerCase().includes(lowercasedQuery) ||
        user.email.toLowerCase().includes(lowercasedQuery)
      );
    });
    setFilteredUsers(filtered);
    setSelectedUsers([]);
    setSelectAll(false);
  }, [searchQuery, users]);

  const fetchUsers = async () => {
    if (!client) return;
  
    try {
      const input = {
        TableName: "users",
      };
      const command = new ScanCommand(input);
      const data = await client.send(command);
      console.log("Users", data.Items);
      return data.Items;
    } catch (err) {
      console.error("Error scanning items:", err);
      throw err;
    }
  };
  
  const updateUserField = async (email, field, value) => {
    try {
      const params = {
        TableName: "users",
        Key: {
          email: { S: email },
          id: { S: email }
        },
        UpdateExpression: `SET #field = :value`,
        ExpressionAttributeNames: {
          "#field": field
        },
        ExpressionAttributeValues: {
          ":value": field === 'rewardsPoints' ? { N: value.toString() } :
                    field === 'localPickup' ? { BOOL: value } :
                    { S: value.toString() } 
        }
      };
  
      const command = new UpdateItemCommand(params);
      console.log("Command", command);
      await client.send(command);
      console.log("User field updated successfully");
    } catch (err) {
      console.error("Error updating user field:", err);
    }
  };
  
  const parseUsers = (items) => {
    return items.map((user) => ({
      id: user.id?.S || '',
      email: user.email?.S || '',
      firstName: user.firstName?.S || '',
      lastName: user.lastName?.S || '',
      instagram_username: user.instagram_username?.S || '',
      rewardsPoints: parseInt(user.rewardsPoints?.N) || 0,
      isTester: user.isTester?.S === 'true', 
      localPickup: user.localPickup?.BOOL || false,
    }));
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0); 
  };
  
  const handleToggle = (email, event, field) => {
    const newValue = event.target.checked;

    setUsers(users.map(user =>
      user.email === email ? { ...user, [field]: newValue } : user
    ));

    setEditedUsers((prev) => ({
      ...prev,
      [email]: { ...prev[email], [field]: newValue }
    }));
  };

  const handlePointsChange = (email, event) => {
    const newValue = parseInt(event.target.value, 10);

    setUsers(users.map(user =>
      user.email === email ? { ...user, rewardsPoints: newValue } : user
    ));

    setEditedUsers((prev) => ({
      ...prev,
      [email]: { ...prev[email], rewardsPoints: newValue }
    }));
  };

  const handleSaveChanges = async () => {
    setLoading(true);
    const updates = Object.entries(editedUsers).flatMap(([email, fields]) =>
      Object.entries(fields).map(([field, value]) => ({ email, field, value }))
    );
    await Promise.all(updates.map(update => updateUserField(update.email, update.field, update.value)));
    setLoading(false);
    setShowCheckmark(true);
    setTimeout(() => {
      setShowCheckmark(false);
    }, 2000);
    setEditedUsers({});
    setSelectedUsers([]); 
    setSelectAll(false); 
    const userData = await fetchUsers();
    const parsedData = parseUsers(userData);
    setUsers(parsedData);
    setFilteredUsers(parsedData);
  };

  const handleRewardPointsChange = (event) => {
    setRewardPoints(parseInt(event.target.value, 10));
  };

  const handleAddRewardPoints = async () => {
    setLoading(true);
    await Promise.all(selectedUsers.map(async (userId) => {
      const user = users.find((u) => u.id === userId);
      if (user) {
        const newPoints = user.rewardsPoints + rewardPoints;
        await updateUserField(user.email, 'rewardsPoints', newPoints);
      }
    }));
    setLoading(false);
    setSelectedUsers([]);
    setRewardPoints(0);
    const userData = await fetchUsers();
    const parsedData = parseUsers(userData);
    setUsers(parsedData);
    setFilteredUsers(parsedData);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedUsers = filteredUsers.map((user) => user.id);
      setSelectedUsers(newSelectedUsers);
      setSelectAll(true);
    } else {
      setSelectedUsers([]);
      setSelectAll(false);
    }
  };

  const handleSelectUser = (userId) => {
    const selectedIndex = selectedUsers.indexOf(userId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedUsers, userId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedUsers.slice(1));
    } else if (selectedIndex === selectedUsers.length - 1) {
      newSelected = newSelected.concat(selectedUsers.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedUsers.slice(0, selectedIndex),
        selectedUsers.slice(selectedIndex + 1),
      );
    }

    setSelectedUsers(newSelected);
    setSelectAll(newSelected.length === filteredUsers.length);
  };

  const paginatedUsers = filteredUsers.slice(
    currentPage * rowsPerPage,
    currentPage * rowsPerPage + rowsPerPage
  );
  
  const isUserSelected = (userId) => selectedUsers.indexOf(userId) !== -1;

  return (
    <Box sx={{ maxWidth: '100%', margin: 'auto', p: 3 }}>
      <Typography 
        variant={isLargeScreen ? 'h3' : isSmallScreen ? 'h5' : 'h4'} 
        gutterBottom 
        sx={{ 
          fontWeight: 'bold', 
          color: 'primary.main', 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center', 
          textAlign: 'center', 
          textTransform: 'uppercase', 
          letterSpacing: 4, 
          textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)' 
        }}
      >
        <UserIcon sx={{ mr: 2 }} /> User Management
      </Typography>
      <Paper elevation={8} sx={{ p: 3, borderRadius: 4, backgroundColor: 'background.paper', backgroundImage: 'linear-gradient(45deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.05))', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)' }}>
        <StyledSearchBar>
          <Search sx={{ mr: 2, color: 'text.secondary', fontSize: 28 }} />
          <StyledSearchInput
            placeholder="Search users..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <Tooltip title="Refresh" TransitionComponent={Zoom} arrow>
            <IconButton onClick={() => {
              setUsers([]);
              setFilteredUsers([]);
              fetchUserData();
            }} disabled={loading} sx={{ ml: 1, transition: 'transform 0.3s ease-in-out', '&:hover': { transform: 'rotate(360deg)' } }}>
              <Refresh sx={{ fontSize: 28 }} />
            </IconButton>
          </Tooltip>
        </StyledSearchBar>
        <StyledTableContainer>
          <Table size={isLargeScreen ? 'medium' : 'small'}>
            <TableHead>
              <TableRow>
                <ResponsiveTableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={selectedUsers.length > 0 && selectedUsers.length < filteredUsers.length}
                    checked={selectAll}
                    onChange={handleSelectAllClick}
                    icon={<Stars />}
                    checkedIcon={<Stars />}
                  />
                </ResponsiveTableCell>
                <ResponsiveTableCell><ResponsiveText>First Name</ResponsiveText></ResponsiveTableCell>
                <ResponsiveTableCell><ResponsiveText>Last Name</ResponsiveText></ResponsiveTableCell>
                <Hidden mdDown>
                  <ResponsiveTableCell><ResponsiveText>Email</ResponsiveText></ResponsiveTableCell>
                </Hidden>
                <Hidden lgDown>
                  <ResponsiveTableCell>
                    <ResponsiveText>
                    <Instagram sx={{ verticalAlign: 'middle', mr: 0.5, color: '#E1306C' }} />
                      Instagram
                    </ResponsiveText>
                  </ResponsiveTableCell>
                </Hidden>
                <ResponsiveTableCell><ResponsiveText>Reward Points</ResponsiveText></ResponsiveTableCell>
                <ResponsiveTableCell><ResponsiveText>Tester</ResponsiveText></ResponsiveTableCell>
                <Hidden mdDown>
                  <ResponsiveTableCell><ResponsiveText>Local Pickup</ResponsiveText></ResponsiveTableCell>
                </Hidden>
                <ResponsiveTableCell><ResponsiveText>Order History</ResponsiveText></ResponsiveTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <AnimatePresence>
                {paginatedUsers.map((user, index) => {
                  const isSelected = isUserSelected(user.id);
  
                  return (
                    <motion.tr
                      key={user.id}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      transition={{ duration: 0.4, delay: index * 0.1, type: 'spring', stiffness: 300, damping: 20 }}
                    >
                      <ResponsiveTableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isSelected}
                          onClick={() => handleSelectUser(user.id)}
                          icon={<Stars />}
                          checkedIcon={<Stars />}
                        />
                      </ResponsiveTableCell>
                      <ResponsiveTableCell><ResponsiveText>{user.firstName}</ResponsiveText></ResponsiveTableCell>
                      <ResponsiveTableCell><ResponsiveText>{user.lastName}</ResponsiveText></ResponsiveTableCell>
                      <Hidden mdDown>
                        <ResponsiveTableCell><ResponsiveText>{user.email}</ResponsiveText></ResponsiveTableCell>
                      </Hidden>
                      <Hidden lgDown>
                        <ResponsiveTableCell><ResponsiveText>{user.instagram_username}</ResponsiveText></ResponsiveTableCell>
                      </Hidden>
                      <ResponsiveTableCell>
                        <TextField
                          type="number"
                          value={user.rewardsPoints}
                          onChange={(event) => handlePointsChange(user.email, event)}
                          size="small"
                          sx={{ width: isSmallScreen ? 60 : 100 }}
                          inputProps={{
                            min: 0,
                            style: { textAlign: 'center', fontSize: isSmallScreen ? '0.8rem' : '1rem' },
                          }}
                        />
                      </ResponsiveTableCell>
                      <ResponsiveTableCell>
                        <Switch
                          checked={user.isTester}
                          onChange={(event) => handleToggle(user.email, event, 'isTester')}
                          color="primary"
                          size={isSmallScreen ? 'small' : 'medium'}
                          sx={{ '& .MuiSwitch-thumb': { backgroundColor: user.isTester ? '#4caf50' : '#f44336' } }}                        
                        />
                      </ResponsiveTableCell>
                      <Hidden mdDown>
                        <ResponsiveTableCell>
                          <Switch
                            checked={user.localPickup}
                            onChange={(event) => handleToggle(user.email, event, 'localPickup')}
                            color="primary"
                            size={isSmallScreen ? 'small' : 'medium'}
                            sx={{ '& .MuiSwitch-thumb': { backgroundColor: user.localPickup ? '#4caf50' : '#f44336' } }}
                          />
                        </ResponsiveTableCell>
                      </Hidden>
                      <ResponsiveTableCell>
                        <Tooltip title="View Order History" TransitionComponent={Zoom} arrow>
                          <IconButton
                            component={Link}
                            to={`/users/${user.email}`}
                            color="primary"
                            size={isSmallScreen ? 'small' : 'medium'}
                            sx={{ transition: 'transform 0.3s ease-in-out', '&:hover': { transform: 'scale(1.2)' } }}
                          >
                            <ShoppingCart sx={{ fontSize: isSmallScreen ? 20 : 28 }} />
                          </IconButton>
                        </Tooltip>
                      </ResponsiveTableCell>
                    </motion.tr>
                  );
                })}
              </AnimatePresence>
            </TableBody>
          </Table>
        </StyledTableContainer>
        <TablePagination
          component="div"
          count={filteredUsers.length}
          page={currentPage}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Users per page"
        />
        <Box sx={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', alignItems: 'center', justifyContent: 'space-between', mt: 3 }}>
          <Button
            variant="contained"
            color="primary"
            size={isLargeScreen ? 'large' : 'medium'}
            onClick={handleSaveChanges}
            disabled={loading}
            startIcon={loading ? <CircularProgress size={24} /> : <Check />}
            sx={{ mb: isSmallScreen ? 2 : 0, fontWeight: 'bold', textTransform: 'uppercase', transition: 'transform 0.3s ease-in-out', '&:hover': { transform: 'scale(1.05)' } }}
          >
            {loading ? 'Saving...' : 'Save Changes'}
          </Button>
          {showCheckmark && (
            <Box sx={{ ml: isSmallScreen ? 0 : 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Checkmark size={isSmallScreen ? 'small' : 'large'} color="#4caf50" />
              <Typography variant={isLargeScreen ? 'h6' : 'body1'} sx={{ ml: 1, color: '#4caf50', fontWeight: 'bold', textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)' }}>
                Changes Saved!
              </Typography>
            </Box>
          )}
        </Box>
      </Paper>
      {selectedUsers.length > 0 && (
        <Paper elevation={8} sx={{ mt: 4, p: 3, borderRadius: 4, backgroundColor: 'background.paper', backgroundImage: 'linear-gradient(45deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.05))', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)' }}>
          <Typography variant={isSmallScreen ? 'h6' : 'h4'} gutterBottom sx={{ fontWeight: 'bold', color: 'primary.main', textAlign: 'center', textTransform: 'uppercase', letterSpacing: 2, textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)' }}>
            Add Reward Points
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', alignItems: 'center', justifyContent: 'center' }}>
            <TextField
              type="number"
              label="Reward Points"
              value={rewardPoints}
              onChange={handleRewardPointsChange}
              sx={{ mr: isSmallScreen ? 0 : 2, mb: isSmallScreen ? 2 : 0, width: isSmallScreen ? '100%' : 200 }}
              size={isSmallScreen ? 'small' : 'medium'}
              inputProps={{
                min: 0,
                style: { textAlign: 'center', fontSize: isSmallScreen ? '1rem' : '1.2rem' },
              }}
            />
            <Button
              variant="contained"
              color="primary"
              size={isSmallScreen ? 'medium' : 'large'}
              onClick={handleAddRewardPoints}
              disabled={loading}
              startIcon={loading ? <CircularProgress size={24} /> : <Add />}
              sx={{ fontWeight: 'bold', textTransform: 'uppercase', transition: 'transform 0.3s ease-in-out', '&:hover': { transform: 'scale(1.05)' } }}
            >
              {loading ? 'Adding...' : 'Add Points'}
            </Button>
          </Box>
        </Paper>
      )}
    </Box>
  );
};

export default UserTable;
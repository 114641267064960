import React, { useState } from 'react';
import { 
  AppBar, Toolbar, Typography, IconButton, Avatar, Button, 
  Menu, MenuItem, ListItemIcon, ListItemText, Drawer, List,
  useMediaQuery, useTheme, Collapse
} from '@mui/material';
import { 
  Menu as MenuIcon, Dashboard, People, ListAlt, Inventory, 
  Stars, Settings, Logout, ExpandMore, ExpandLess
} from '@mui/icons-material';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Link } from 'react-router-dom';

const MyAppBar = ({ handleDrawerToggle }) => {
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenus, setOpenMenus] = useState({});
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenus({});
  };

  const handleNestedMenuToggle = (menuName) => {
    setOpenMenus(prev => ({ ...prev, [menuName]: !prev[menuName] }));
  };

  const menuItems = [
    { icon: <Dashboard />, text: 'Dashboard', link: '/' },
    { 
      icon: <People />, 
      text: 'Users', 
      subitems: [
        { text: 'All Users', link: '/users' },
        { text: 'Pending Testers', link: '/users/pending' },
        { text: 'Seed Requests', link: '/users/seed-requests' },
      ]
    },
    { 
      icon: <ListAlt />, 
      text: 'Orders', 
      subitems: [
        { text: 'All Orders', link: '/orders' },
        { text: 'Fulfilled Orders', link: '/orders/fulfilled' },
        { text: 'Unfulfilled Orders', link: '/orders/unfulfilled' },
      ]
    },
    { 
      icon: <Inventory />, 
      text: 'Listings', 
      subitems: [
        { text: 'All Listings', link: '/listings' },
        { text: 'Active Listings', link: '/listings/active' },
        { text: 'Inactive Listings', link: '/listings/inactive' },
        { text: 'Create Seed Listing', link: '/create-listing' },
      ]
    },
    { icon: <Stars />, text: 'Rewards', link: '/rewards' },
    { icon: <Settings />, text: 'Settings', link: '/settings' },
  ];

  const renderMenuItems = (items, nested = false) => (
    items.map((item, index) => (
      <React.Fragment key={index}>
        {item.subitems ? (
          <MenuItem onClick={() => handleNestedMenuToggle(item.text)}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
            {openMenus[item.text] ? <ExpandLess /> : <ExpandMore />}
          </MenuItem>
        ) : (
          <MenuItem 
            component={Link} 
            to={item.link} 
            onClick={handleClose}
            style={{ paddingLeft: nested ? 32 : 16 }}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </MenuItem>
        )}
        {item.subitems && (
          <Collapse in={openMenus[item.text]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {renderMenuItems(item.subitems, true)}
            </List>
          </Collapse>
        )}
      </React.Fragment>
    ))
  );

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      PaperProps={{
        style: {
          maxHeight: '80vh',
          width: '250px',
        },
      }}
    >
      {renderMenuItems(menuItems)}
    </Menu>
  );

  const renderDrawer = (
    <Drawer
      anchor="left"
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <List style={{ width: 250 }}>
        {renderMenuItems(menuItems)}
      </List>
    </Drawer>
  );

  return (
    <AppBar position="fixed" sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open menu"
          edge="start"
          onClick={isMobile ? handleDrawerToggle : handleClick}
          sx={{ mr: 2 }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap sx={{ flexGrow: 1 }}>
          HG Admin Dashboard
        </Typography>
        {!isMobile && (
          <Button
            color="inherit"
            onClick={handleClick}
            endIcon={<ExpandMore />}
          >
            Menu
          </Button>
        )}
        {user && (
          <>
            <Typography variant="body2" sx={{ mr: 2 }}>
              {user.signInDetails.loginId}
            </Typography>
            <Button color="inherit" onClick={signOut}>Sign out</Button>
          </>
        )}
        <Avatar alt={(user.signInDetails.loginId).toUpperCase()} src="/path/to/avatar.jpg" sx={{ ml: 2 }} />
      </Toolbar>
      {isMobile ? renderDrawer : renderMenu}
    </AppBar>
  );
};

export default MyAppBar;
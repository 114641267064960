import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, TextField, Button, Box, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { DynamoDBClient, GetItemCommand, UpdateItemCommand } from '@aws-sdk/client-dynamodb';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';

import getAwsConfig from "../../awsconfig";

const awsconfig = await getAwsConfig();
const client = new DynamoDBClient(awsconfig);

const EditListingPage = () => {
  const { id, createdDate } = useParams();
  const navigate = useNavigate();
  const [listing, setListing] = useState(null);
  const [loading, setLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState('');
  const [pdfUrl, setPdfUrl] = useState('');
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    fetchListing();
  }, [id, createdDate]);

  const fetchListing = async () => {
    try {
      const params = {
        TableName: 'allSeeds',
        Key: {
          id: { S: id },
          createdDate: { S: createdDate },
        },
      };

      const command = new GetItemCommand(params);
      const { Item } = await client.send(command);

      if (!Item) {
        console.error('Item not found');
        return;
      }

      const formattedItem = {
        id: Item.id.S,
        createdDate: Item.createdDate.S,
        collection: Item.collection.S,
        fullDescription: Item.fullDescription.S,
        imageUrl: Item.image_url.S,
        inventory: Item.inventory.N,
        isActive: Item.isActive.BOOL,
        name: Item.name.S,
        price: Item.price.N,
        testPdf: Item.test_pdf.S,
      };

      setListing(formattedItem);
      setImageUrl(formattedItem.imageUrl);
      setPdfUrl(formattedItem.testPdf);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching listing:', JSON.stringify(error, null, 2));
    }
  };

  const handleChange = (field, value) => {
    setListing({ ...listing, [field]: value });
  };

  const handleFileUpload = async (file, folder) => {
    try {
      const response = await fetch("https://id6eckqs6kz7fgg6euu7jptor40mqgul.lambda-url.us-east-2.on.aws/", {
        method: "POST",
        body: JSON.stringify({
          name: file.name,
          type: file.type,
          content: await fileToBase64(file),
          folder,
        }),
      });

      const data = await response.json();
      if (response.ok) {
        console.log(`Uploaded URL: ${data.url}`);
        if (folder === 'seeds') {
          setImageUrl(data.url);
        } else if (folder === 'reports') {
          setPdfUrl(data.url);
        }
      } else {
        console.error("Error uploading file:", data.error);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleReset = () => {
    setImageUrl('');
    setPdfUrl('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const updateCommand = new UpdateItemCommand({
        TableName: 'allSeeds',
        Key: {
          id: { S: listing.id },
          createdDate: { S: listing.createdDate },
        },
        UpdateExpression: `SET 
          #name = :name, 
          #price = :price, 
          #fullDescription = :fullDescription, 
          #image_url = :imageUrl, 
          #inventory = :inventory, 
          #isActive = :isActive, 
          #collection = :collection, 
          #test_pdf = :testPdf`,
        ExpressionAttributeNames: {
          '#name': 'name',
          '#price': 'price',
          '#fullDescription': 'fullDescription',
          '#image_url': 'image_url',
          '#inventory': 'inventory',
          '#isActive': 'isActive',
          '#collection': 'collection',
          '#test_pdf': 'test_pdf',
        },
        ExpressionAttributeValues: {
          ':name': { S: listing.name },
          ':price': { N: listing.price },
          ':fullDescription': { S: listing.fullDescription },
          ':imageUrl': { S: imageUrl },
          ':inventory': { N: listing.inventory },
          ':isActive': { BOOL: listing.isActive },
          ':collection': { S: listing.collection },
          ':testPdf': { S: pdfUrl },
        },
      });

      await client.send(updateCommand);
      alert('Listing updated successfully!');
      navigate('/listings');
    } catch (error) {
      console.error('Error updating listing:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleDelete = async () => {
    try {
      const params = new UpdateItemCommand({
        TableName: 'allSeeds',
        Key: {
          id: { S: listing.id },
          createdDate: { S: listing.createdDate },
        },
        UpdateExpression: 'SET #isActive = :falseVal',
        ExpressionAttributeNames: {
          '#isActive': 'isActive',
        },
        ExpressionAttributeValues: {
          ':falseVal': { BOOL: false },
        },
      });

      await client.send(params);
      alert('Listing marked as inactive successfully!');
      navigate('/listings'); // Redirect to listings page
    } catch (error) {
      console.error('Error marking listing as inactive:', JSON.stringify(error, null, 2));
      alert('Failed to mark the listing as inactive.');
    }
    setOpenDialog(false);
  };

  const handleViewPdf = () => {
    window.open(pdfUrl, '_blank');
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Container maxWidth="md">
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 4 }}>
        <Typography variant="h4" gutterBottom>Edit Listing</Typography>
        <TextField
          label="Name"
          fullWidth
          value={listing.name}
          onChange={(e) => handleChange('name', e.target.value)}
          sx={{ mt: 2 }}
        />
        <TextField
          label="Price"
          fullWidth
          type="number"
          value={listing.price}
          onChange={(e) => handleChange('price', e.target.value)}
          sx={{ mt: 2 }}
        />
        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
          Edit Description
        </Typography>
        <ReactQuill
          value={listing.fullDescription}
          onChange={(value) => handleChange('fullDescription', value)}
          sx={{ mt: 2 }}
        />
        <TextField
          label="Collection"
          fullWidth
          value={listing.collection}
          onChange={(e) => handleChange('collection', e.target.value)}
          sx={{ mt: 2 }}
        />
        <TextField
          label="Inventory"
          fullWidth
          type="number"
          value={listing.inventory}
          onChange={(e) => handleChange('inventory', e.target.value)}
          sx={{ mt: 2 }}
        />
        <Button
          variant="contained"
          component="label"
          sx={{ mt: 2 }}
        >
          Upload Image
          <input
            type="file"
            hidden
            accept="image/*"
            onChange={(e) => handleFileUpload(e.target.files[0], 'seeds')}
          />
        </Button>
        {imageUrl && <Box component="img" src={imageUrl} alt="Preview" sx={{ mt: 2, width: "100%", height: "auto" }} />}
        
        <Button
          variant="contained"
          component="label"
          sx={{ mt: 2 }}
        >
          Upload PDF
          <input
            type="file"
            hidden
            accept="application/pdf"
            onChange={(e) => handleFileUpload(e.target.files[0], 'reports')}
          />
        </Button>
        {pdfUrl && (
          <Button
            variant="contained"
            color="secondary"
            sx={{ mt: 2 }}
            onClick={handleViewPdf}
          >
            View PDF
          </Button>
        )}
        
        <Button type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 4 }}>
          Update Listing
        </Button>
        <Button 
          fullWidth 
          variant="outlined" 
          color="secondary" 
          sx={{ mt: 2 }} 
          onClick={handleOpenDialog}
        >
          Delete Listing
        </Button>
      </Box>
      
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Listing"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this listing? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default EditListingPage;

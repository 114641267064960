import { Box, Typography } from "@mui/material";
import { styled, keyframes } from "@mui/system";

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

export const ListingCard = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  maxWidth: "300px",
  height: "450px",
  padding: "16px",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  backgroundColor: theme.palette.background.paper,
  transition: "transform 0.3s ease-in-out",
  animation: `${fadeIn} 1s ease-in-out`,
  "&:hover": {
    transform: "scale(1.05)",
  },
  margin: "16px",
  cursor: "pointer",
}));

export const ListingImage = styled("img")({
  width: "100%",
  height: "60%",
  objectFit: "cover",
  borderRadius: "8px",
});

export const ListingContent = styled(Box)({
  width: "100%",
  textAlign: "center",
});

export const ListingTitle = styled(Typography)({
  fontSize: "1.2rem",
  margin: "8px 0",
});

export const ListingPrice = styled(Typography)({
  fontSize: "1rem",
  color: "green",
});

export const ListingDescription = styled(Typography)({
  fontSize: "0.9rem",
  color: "gray",
});

export const StatusIndicator = styled(Typography)(({ theme, isActive }) => ({
  position: 'absolute',
  top: '10px',
  right: '10px',
  padding: '4px 8px',
  borderRadius: '12px',
  fontSize: '0.75rem',
  fontWeight: 'bold',
  opacity: 0.7,
  backgroundColor: isActive ? theme.palette.success.light : theme.palette.error.light,
  color: isActive ? theme.palette.success.contrastText : theme.palette.error.contrastText,
}));
import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  Grid,
  Button,
  Container,
  Paper,
  Box,
} from '@mui/material';
import { Info, ArrowBack, ErrorOutline, CheckCircle, PendingActions, LocalShipping, CalendarToday } from '@mui/icons-material';
import { DynamoDBClient, QueryCommand } from '@aws-sdk/client-dynamodb';
import awsconfig from '../../awsconfig';
import { useParams, useNavigate } from 'react-router-dom';
import { keyframes } from '@emotion/react';

const MainSection = styled(Container)`
  padding: 16px;
  color: black;
`;

const OrderListContainer = styled.div`
  margin: 20px;
  animation: ${keyframes`
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  `} 0.5s ease-in-out;
`;

const OrderItemContainer = styled(Paper)`
  padding: 16px;
  background-color: #F5F5F5;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  &:hover {
    background-color: #E0E0E0;
  }
  .MuiListItemText-secondary {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const NoOrdersMessage = styled.div`
  text-align: center;
  margin-top: 20px;
  color: #757575;
`;

const OrderSummaryPaper = styled(Paper)({
  padding: 16,
  borderRadius: 8,
  backgroundImage: 'linear-gradient(45deg, #E0E0E0 30%, #F5F5F5 90%)',
  boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.1), 0px 5px 8px 0px rgba(0,0,0,0.07), 0px 1px 14px 0px rgba(0,0,0,0.06)',
  color: '#424242',
});

const AnimatedTypography = styled(Typography)`
  animation: ${keyframes`
    0% {
      transform: scale(0);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  `} 0.5s ease-in-out;
`;

const AnimatedListItem = styled(ListItem)`
  animation: ${keyframes`
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  `} 0.3s ease-in-out;
`;

const client = new DynamoDBClient(awsconfig);
const parseOrders = (items) => {
    return items.map((item) => ({
      id: item.id?.S || '',
      email: item.email?.S || '',
      createDate: item.createDate?.S || '',
      billingFirstName: item.billingFirstName?.S || '',
      billingLastName: item.billingLastName?.S || '',
      billingEmail: item.billingEmail?.S || '',
      billingPhone: item.billingPhone?.S || '',
      billingAddress: item.billingAddress?.S || '',
      billingAddress2: item.billingAddress2?.S || '',
      billingCity: item.billingCity?.S || '',
      billingState: item.billingState?.S || '',
      billingZip: item.billingZip?.S || '',
      billingCountry: item.billingCountry?.S || '',
      shippingFirstName: item.shippingFirstName?.S || '',
      shippingLastName: item.shippingLastName?.S || '',
      shippingEmail: item.shippingEmail?.S || '',
      shippingAddress: item.shippingAddress?.S || '',
      shippingAddress2: item.shippingAddress2?.S || '',
      shippingCity: item.shippingCity?.S || '',
      shippingState: item.shippingState?.S || '',
      shippingZip: item.shippingZip?.S || '',
      shippingCountry: item.shippingCountry?.S || '',
      shippingMethod: item.shippingMethod?.S || '',
      merchandiseTrackingNumber: item.merchandiseTrackingNumber?.S || '',
      otherTrackingNumber: item.otherTrackingNumber?.S || '',
      merchandiseFulfillDate: item.merchandiseFulfillDate?.S || '',
      otherFulfillDate: item.otherFulfillDate?.S || '',
      isMerchandiseFulfilled: item.isMerchandiseFulfilled?.BOOL || false,
      isOtherFulfilled: item.isOtherFulfilled?.BOOL || false,
      total: parseFloat(item.total?.N) || 0,
      subtotal: parseFloat(item.subtotal?.N) || 0,
      shippingCost: parseFloat(item.shippingCost?.N) || 0,
      tax: item.tax?.S || '',
      modifyDate: item.modifyDate?.S || '',
      merchandiseId: item.merchandiseId?.S || '',
      merchandise: parseMerchandise(item.merchandise?.L) || [],
      otherProducts: parseProducts(item.otherProducts?.L) || [],
      basketData: parseBasketData(item.basketData?.S) || [],
    }));
  };
  
  const parseMerchandise = (merchandiseList) => {
    if (!merchandiseList) return [];
    return merchandiseList.map((merchandiseItem) => {
      const item = merchandiseItem.M;
      return {
        id: item.id?.S || '',
        title: item.title?.S || '',
        quantity: parseInt(item.quantity?.N, 10) || 0,
        price: parseFloat(item.price?.N) || 0,
      };
    });
  };
  
  const parseProducts = (productsList) => {
    if (!productsList) return [];
    return productsList.map((productItem) => {
      const item = productItem.M;
      return {
        id: item.id?.S || '',
        title: item.title?.S || '',
        quantity: parseInt(item.quantity?.N, 10) || 0,
        price: parseFloat(item.price?.N) || 0,
      };
    });
  };
  
  const parseBasketData = (basketDataString) => {
    if (!basketDataString) return [];
    try {
      return JSON.parse(basketDataString).map((item) => ({
        id: item.id || '',
        title: item.title || '',
        image: item.image || '',
        price: parseFloat(item.price) || 0,
        size: item.size || '',
        quantity: parseInt(item.quantity, 10) || 0,
      }));
    } catch (error) {
      console.error('Error parsing basketData:', error);
      return [];
    }
  };
  
  const queryOrdersByEmail = async (email) => {
    if (!email) {
      throw new Error('Email is required to query orders');
    }
    try {
      const params = {
        TableName: 'orders',
        KeyConditionExpression: 'email = :email',
        ExpressionAttributeValues: {
          ':email': { S: email.toLowerCase() },
        },
      };
  
      const command = new QueryCommand(params);
      const data = await client.send(command);
      if (!data.Items) {
        throw new Error('No orders found');
      }
      return parseOrders(data.Items);
    } catch (err) {
      console.error('Error querying items:', err);
      throw err;
    }
  };


  const UserOrderHistoryContent = () => {
    const { userId: userEmail } = useParams();
    const navigate = useNavigate();
    const [orders, setOrders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      const fetchOrders = async () => {
        setLoading(true);
        try {
          const fetchedOrders = await queryOrdersByEmail(userEmail);
          setOrders(fetchedOrders);
        } catch (err) {
          console.error('Error fetching orders:', err);
          setError(err.message);
        } finally {
          setLoading(false);
        }
      };
  
      fetchOrders();
    }, [userEmail]);
  
    const handleOrderClick = (orderId) => {
      const order = orders.find((order) => order.id === orderId);
      setSelectedOrder(order);
    };
  
    const handleBackClick = () => {
      setSelectedOrder(null);
    };
  
    if (error) {
      return (
        <Container>
          <Typography variant="h6" color="error">
            {error}
          </Typography>
          <Button variant="contained" color="primary" onClick={() => navigate('/users')}>
            Go Back
          </Button>
        </Container>
      );
    }
  
    return (
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MainSection>
                {!selectedOrder ? (
                  <OrderListContainer>
                    <Typography variant="h4" gutterBottom>
                      Order History for {userEmail}
                    </Typography>
                    {loading ? (
                      <p>Loading...</p>
                    ) : orders.length === 0 ? (
                      <NoOrdersMessage>
                        <ErrorOutline color="error" fontSize="large" />
                        <Typography variant="h6" color="textSecondary">
                          No orders from {userEmail}
                        </Typography>
                      </NoOrdersMessage>
                    ) : (
                      <List>
                        {orders.map((order) => (
                          <OrderItemContainer
                            key={order.id || `${order.email}-${order.createDate}`}
                            onClick={() => handleOrderClick(order.id)}
                            elevation={3}
                          >
                            <Grid container spacing={2} alignItems="center">
                              <Grid item xs={12} sm={6}>
                                <ListItemText
                                  primary={
                                    <>
                                      <Typography variant="subtitle1" component="span">
                                        Order #: {order.id}
                                      </Typography>
                                      <br />
                                      <Typography variant="body2" color="textSecondary">
                                        Tracking #: {order.merchandiseTrackingNumber || 'N/A'}
                                      </Typography>
                                    </>
                                  }
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <ListItemText
                                  primary={
                                    <>
                                      <Typography variant="subtitle1" component="span">
                                        Status:{' '}
                                        {order.isMerchandiseFulfilled ? (
                                          <>
                                            <CheckCircle color="success" fontSize="small" /> Fulfilled
                                          </>
                                        ) : (
                                          <>
                                            <PendingActions color="warning" fontSize="small" /> Pending
                                          </>
                                        )}
                                      </Typography>
                                      <br />
                                      <Typography variant="body2" color="textSecondary">
                                        Date: {order.createDate ? new Date(order.createDate).toLocaleString() : 'N/A'}
                                      </Typography>
                                    </>
                                  }
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <ListItemText
                                  primary={
                                    <>
                                      <Typography variant="subtitle1" component="span">
                                        Items: {order.basketData?.length || 0}
                                      </Typography>
                                      <br />
                                      <Typography variant="body2" color="textSecondary">
                                        Order Items: {order.basketData?.map((item) => item.title).join(', ') || 'N/A'}
                                      </Typography>
                                    </>
                                  }
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} container justifyContent="flex-end">
                                <Button
                                  variant="contained"
                                  color="primary"
                                  startIcon={<Info />}
                                  onClick={() => handleOrderClick(order.id)}
                                >
                                  View Summary
                                </Button>
                              </Grid>
                            </Grid>
                          </OrderItemContainer>
                        ))}
                      </List>
                    )}
                  </OrderListContainer>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleBackClick}
                      startIcon={<ArrowBack />}
                      style={{ marginTop: '20px' }}
                    >
                      Back to Orders
                    </Button>
                    <OrderSummaryPaper elevation={3} sx={{ p: 2, mt: 2 }}>
                      <AnimatedTypography variant="h4" gutterBottom>
                        Order Summary
                      </AnimatedTypography>
                      <Box>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <AnimatedTypography variant="h6">
                              <ListItemIcon>
                                <Info />
                              </ListItemIcon>
                              Order ID: {selectedOrder.id}
                            </AnimatedTypography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <AnimatedTypography variant="body1">
                              <ListItemIcon>
                                <LocalShipping />
                              </ListItemIcon>
                              Tracking Number: {selectedOrder.merchandiseTrackingNumber || 'N/A'}
                            </AnimatedTypography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <AnimatedTypography variant="body1">
                              <ListItemIcon>
                                {selectedOrder.isMerchandiseFulfilled ? <CheckCircle color="success" /> : <PendingActions color="warning" />}
                              </ListItemIcon>
                              Status: {selectedOrder.isMerchandiseFulfilled ? 'Fulfilled' : 'Pending'}
                            </AnimatedTypography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <AnimatedTypography variant="body1">
                              <ListItemIcon>
                                <CalendarToday />
                              </ListItemIcon>
                              Order Date: {selectedOrder.createDate ? new Date(selectedOrder.createDate).toLocaleString() : 'N/A'}
                            </AnimatedTypography>
                          </Grid>
                        </Grid>
                      </Box>
    
                      <Box mt={4}>
                        <AnimatedTypography variant="h6">Items</AnimatedTypography>
                        <List>
                          {selectedOrder.basketData?.map((item, index) => (
                            <AnimatedListItem key={index}>
                              <ListItemIcon>
                                <CheckCircle color="success" />
                              </ListItemIcon>
                              <ListItemText primary={item.title || 'Unknown'} />
                            </AnimatedListItem>
                          )) || <AnimatedTypography>No items found.</AnimatedTypography>}
                        </List>
                      </Box>
                    </OrderSummaryPaper>
                  </>
                )}
              </MainSection>
            </Grid>
          </Grid>
        </Container>
      );
    };
    
    export default UserOrderHistoryContent;
import React, { useEffect, useState, useCallback } from 'react';
import { Box, Paper, Typography, TextField, Button, CircularProgress, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Zoom, Fade, Snackbar, Alert } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import { DynamoDBClient, ScanCommand, UpdateItemCommand, PutItemCommand, DeleteItemCommand } from "@aws-sdk/client-dynamodb";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

import getAwsConfig from "../../awsconfig";
const awsconfig = await getAwsConfig();
const client = new DynamoDBClient(awsconfig);

const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  
  const date = new Date(dateString);
  if (isNaN(date.getTime())) return 'Invalid Date';

  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZoneName: 'short'
  };
  
  return new Intl.DateTimeFormat('en-US', options).format(date);
};

const fetchRewards = async () => {
  try {
    const input = {
      TableName: "rewards",
    };
    const command = new ScanCommand(input);
    const data = await client.send(command);
    console.log("Fetched Rewards:", JSON.stringify(data.Items, null, 2));
    return data.Items;
  } catch (err) {
    console.error("Error scanning items:", err);
    throw err;
  }
};


const updateRewardField = async (name, field, value) => {
  try {
    const isoDate = new Date().toISOString();
    const formattedDate = formatDate(isoDate);
    let attributeValue;

    if (['dollarValue', 'percentValue', 'pointValue'].includes(field)) {
      attributeValue = { N: value.toString() };
    } else {
      attributeValue = { S: value.toString() };
    }

    const params = {
      TableName: "rewards",
      Key: {
        name: { S: name }
      },
      UpdateExpression: `SET #${field} = :value, modifyDate = :modifyDate, modifyDateReadable = :modifyDateReadable`,
      ExpressionAttributeNames: {
        [`#${field}`]: field
      },
      ExpressionAttributeValues: {
        ":value": attributeValue,
        ":modifyDate": { S: isoDate },
        ":modifyDateReadable": { S: formattedDate }
      },
      ReturnValues: "ALL_NEW"
    };

    console.log('Update params:', JSON.stringify(params, null, 2));

    const command = new UpdateItemCommand(params);
    console.log("Command:", JSON.stringify(command, null, 2));
    const result = await client.send(command);

    console.log(`Update result:`, JSON.stringify(result, null, 2));

    if (result.Attributes) {
      console.log(`Updated reward:`, JSON.stringify(result.Attributes, null, 2));
    } else {
      console.warn(`No attributes returned from update operation`);
    }

    return result;
  } catch (err) {
    console.error(`Error updating reward field ${field} for ${name}:`, err);
    throw err;
  }
};




const createReward = async (reward) => {
  try {
    const validReward = {
      name: reward.name || 'Unnamed Reward',
      createDate: reward.createDate || new Date().toISOString(),
      deleteDate: reward.deleteDate || '',
      dollarValue: reward.dollarValue !== undefined ? reward.dollarValue.toString() : '0',
      itemValue: reward.itemValue || '',
      modifyDate: new Date().toISOString(),
      percentValue: reward.percentValue !== undefined ? reward.percentValue.toString() : '0',
      pointValue: reward.pointValue !== undefined ? reward.pointValue.toString() : '0',
    };

    const params = {
      TableName: "rewards",
      Item: {
        name: { S: validReward.name },
        createDate: { S: validReward.createDate },
        deleteDate: { S: validReward.deleteDate },
        dollarValue: { N: validReward.dollarValue },
        itemValue: { S: validReward.itemValue },
        modifyDate: { S: validReward.modifyDate },
        percentValue: { N: validReward.percentValue },
        pointValue: { N: validReward.pointValue },
      }
    };

    const command = new PutItemCommand(params);
    await client.send(command);
    console.log("Reward created successfully");
  } catch (err) {
    console.error("Error creating reward:", err);
  }
};

const deleteReward = async (name) => {
  try {
    const params = {
      TableName: "rewards",
      Key: {
        name: { S: name }
      },
    };

    const command = new DeleteItemCommand(params);
    await client.send(command);
    console.log("Reward deleted successfully");
  } catch (err) {
    console.error("Error deleting reward:", err);
  }
};

const parseRewards = (items) => {
  return items.map((item) => ({
    name: item.name?.S || '',
    createDate: item.createDate?.S || '',
    deleteDate: item.deleteDate?.S || '',
    dollarValue: parseFloat(item.dollarValue?.N) || 0,
    itemValue: item.itemValue?.S || '',
    modifyDate: item.modifyDate?.S || '',
    modifyDateReadable: item.modifyDateReadable?.S || '',
    percentValue: parseFloat(item.percentValue?.N) || 0,
    pointValue: parseInt(item.pointValue?.N) || 0,
  }));
};

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
`;

const AnimatedTableRow = styled(TableRow)(({ theme }) => ({
  transition: 'background-color 0.3s ease',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const AnimatedTableBody = styled(TableBody)({
  '& > tr': {
    animation: `${fadeIn} 0.5s ease-in-out`,
  },
});

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    borderRadius: theme.shape.borderRadius,
  },
  '& .MuiInputBase-input': {
    padding: '10px 14px',
  },
}));

const ActionButton = styled(IconButton)(({ theme }) => ({
  padding: 8,
  color: theme.palette.text.secondary,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const RewardTable = () => {
  const [rewards, setRewards] = useState([]);
  const [editingReward, setEditingReward] = useState(null);
  const [tempEditingReward, setTempEditingReward] = useState(null);
  const [loading, setLoading] = useState(true);
  const [newRewardName, setNewRewardName] = useState("");
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const fetchRewardData = useCallback(async () => {
    try {
      const rewardData = await fetchRewards();
      const parsedRewards = parseRewards(rewardData);
      setRewards(parsedRewards);
    } catch (err) {
      console.error("Error fetching reward data:", err);
      setSnackbar({ open: true, message: 'Failed to fetch rewards', severity: 'error' });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    console.log('Fetching reward data...');
    fetchRewardData().then(() => {
      console.log('Reward data fetched successfully');
    }).catch(error => {
      console.error('Error fetching reward data:', error);
    });
  }, [fetchRewardData]);

  const handleEdit = (reward) => {
    console.log('Starting to edit reward:', JSON.stringify(reward, null, 2));
    setEditingReward({ ...reward });
    setTempEditingReward({ ...reward });
  };

  const handleCancelEdit = () => {
    setEditingReward(null);
    setTempEditingReward(null);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      console.log('Attempting to save reward:', JSON.stringify(tempEditingReward, null, 2));
      console.log('Current editingReward:', JSON.stringify(editingReward, null, 2));
      
      const fieldsToUpdate = ['dollarValue', 'itemValue', 'percentValue', 'pointValue'];
      let updatesPerformed = false;
  
      for (const field of fieldsToUpdate) {
        console.log(`Checking field: ${field}`);
        console.log(`Temp value: ${tempEditingReward[field]}, Original value: ${editingReward[field]}`);
        
        if (tempEditingReward[field] !== editingReward[field]) {
          let value = tempEditingReward[field];
          if (field === 'dollarValue' || field === 'percentValue') {
            value = parseFloat(value);
            if (isNaN(value)) value = 0;
          } else if (field === 'pointValue') {
            value = parseInt(value, 10);
            if (isNaN(value)) value = 0;
          }
          console.log(`Updating field: ${field}, old value: ${editingReward[field]}, new value: ${value}`);
          try {
            const updateResult = await updateRewardField(editingReward.name, field, value);
            console.log(`Update result for ${field}:`, JSON.stringify(updateResult, null, 2));
            updatesPerformed = true;
          } catch (updateError) {
            console.error(`Error updating field ${field}:`, updateError);
          }
        } else {
          console.log(`No change detected for field: ${field}`);
        }
      }
  
      if (updatesPerformed) {
        setSnackbar({ open: true, message: 'Reward updated successfully', severity: 'success' });
      } else {
        setSnackbar({ open: true, message: 'No changes detected', severity: 'info' });
      }
      
      setEditingReward(null);
      setTempEditingReward(null);
      await fetchRewardData();
    } catch (error) {
      console.error("Error in handleSave:", error);
      setSnackbar({ open: true, message: 'Failed to update reward: ' + error.message, severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleAddReward = async () => {
    if (newRewardName.trim() === "") {
      setSnackbar({ open: true, message: 'Please enter a valid reward name', severity: 'warning' });
      return;
    }
    setLoading(true);
    try {
      const newReward = {
        name: newRewardName,
        createDate: new Date().toISOString(),
        deleteDate: '',
        dollarValue: 0,
        itemValue: '',
        modifyDate: new Date().toISOString(),
        percentValue: 0,
        pointValue: 0,
      };
      await createReward(newReward);
      setNewRewardName("");
      setSnackbar({ open: true, message: 'New reward added successfully', severity: 'success' });
      await fetchRewardData();
    } catch (error) {
      console.error("Error adding reward:", error);
      setSnackbar({ open: true, message: 'Failed to add new reward', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteReward = async (name) => {
    setLoading(true);
    try {
      await deleteReward(name);
      setSnackbar({ open: true, message: 'Reward deleted successfully', severity: 'success' });
      await fetchRewardData();
    } catch (error) {
      console.error("Error deleting reward:", error);
      setSnackbar({ open: true, message: 'Failed to delete reward', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, open: false });
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: 1200, margin: 'auto', p: 3 }}>
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: 'primary.main' }}>
        Rewards Management
      </Typography>
      <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Create Date</TableCell>
                <TableCell>Dollar Value</TableCell>
                <TableCell>Item Value</TableCell>
                <TableCell>Percent Value</TableCell>
                <TableCell>Point Value</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <AnimatedTableBody>
              {rewards.map((reward) => (
                <Fade in={true} key={reward.name}>
                  <AnimatedTableRow>
                    <TableCell>{reward.name}</TableCell>
                    <TableCell>{formatDate(reward.createDate)}</TableCell>
                    <TableCell>
                      {editingReward && editingReward.name === reward.name ? (
                        <StyledTextField
                          type="number"
                          value={tempEditingReward.dollarValue}
                          onChange={(e) => setTempEditingReward({ ...tempEditingReward, dollarValue: e.target.value })}
                          fullWidth
                        />
                      ) : (
                        `$${reward.dollarValue.toFixed(2)}`
                      )}
                    </TableCell>
                    <TableCell>
                      {editingReward && editingReward.name === reward.name ? (
                        <StyledTextField
                          value={tempEditingReward.itemValue}
                          onChange={(e) => setTempEditingReward({ ...tempEditingReward, itemValue: e.target.value })}
                          fullWidth
                        />
                      ) : (
                        reward.itemValue
                      )}
                    </TableCell>
                    <TableCell>
                      {editingReward && editingReward.name === reward.name ? (
                        <StyledTextField
                          type="number"
                          value={tempEditingReward.percentValue}
                          onChange={(e) => setTempEditingReward({ ...tempEditingReward, percentValue: e.target.value })}
                          fullWidth
                        />
                      ) : (
                        `${reward.percentValue}%`
                      )}
                    </TableCell>
                    <TableCell>
                      {editingReward && editingReward.name === reward.name ? (
                        <StyledTextField
                          type="number"
                          value={tempEditingReward.pointValue}
                          onChange={(e) => setTempEditingReward({ ...tempEditingReward, pointValue: e.target.value })}
                          fullWidth
                        />
                      ) : (
                        reward.pointValue
                      )}
                    </TableCell>
                    <TableCell>
                      {editingReward && editingReward.name === reward.name ? (
                        <>
                          <Tooltip title="Save" TransitionComponent={Zoom}>
                            <ActionButton onClick={() => handleSave(editingReward)} color="primary">
                              <SaveIcon />
                            </ActionButton>
                          </Tooltip>
                          <Tooltip title="Cancel" TransitionComponent={Zoom}>
                            <ActionButton onClick={handleCancelEdit} color="secondary">
                              <CancelIcon />
                            </ActionButton>
                          </Tooltip>
                        </>
                      ) : (
                        <>
                          <Tooltip title="Edit" TransitionComponent={Zoom}>
                            <ActionButton onClick={() => handleEdit(reward)} color="primary">
                              <EditIcon />
                            </ActionButton>
                          </Tooltip>
                          <Tooltip title="Delete" TransitionComponent={Zoom}>
                            <ActionButton onClick={() => handleDeleteReward(reward.name)} color="secondary">
                              <DeleteIcon />
                            </ActionButton>
                          </Tooltip>
                        </>
                      )}
                    </TableCell>
                  </AnimatedTableRow>
                </Fade>
              ))}
            </AnimatedTableBody>
          </Table>
        </TableContainer>
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 3 }}>
          <StyledTextField
            label="New Reward Name"
            value={newRewardName}
            onChange={(e) => setNewRewardName(e.target.value)}
            sx={{ mr: 2, flexGrow: 1 }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddReward}
            disabled={loading}
            startIcon={loading ? <CircularProgress size={20} /> : <AddIcon />}
          >
            Add Reward
          </Button>
        </Box>
      </Paper>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default RewardTable;
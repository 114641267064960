import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  Box,
  Modal,
  Fade,
  Backdrop,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import { DynamoDBClient, ScanCommand, PutItemCommand } from "@aws-sdk/client-dynamodb";
import { CloudUpload, Description, Image, Close } from "@mui/icons-material";
import { keyframes } from "@emotion/react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import getAwsConfig from "../../awsconfig";

const awsconfig = await getAwsConfig();
const client = new DynamoDBClient(awsconfig);

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const CustomInput = styled("input")({
  display: "none",
});

const PreviewImage = styled("img")({
  maxWidth: "100%",
  marginTop: 16,
  borderRadius: 4,
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  animation: `${fadeIn} 0.5s ease-in-out`,
});

const UploadButton = styled(Button)(({ theme }) => ({
  marginTop: 16,
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.common.white,
  "&:hover": {
    backgroundColor: theme.palette.secondary.dark,
  },
}));

const formatDate = (date) => {
  const pad = (num) => num.toString().padStart(2, '0');
  const day = pad(date.getDate());
  const month = pad(date.getMonth() + 1);
  const year = date.getFullYear();
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());

  return `${day}-${month}-${year}-${hours}-${minutes}-${seconds}`;
};

const ListingForm = () => {
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [description2, setDescription2] = useState("");
  const [fullDescription, setFullDescription] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [inventory, setInventory] = useState("");
  const [isSoldOut, setIsSoldOut] = useState(false);
  const [collection, setCollection] = useState("");
  const [testPdf, setTestPdf] = useState("");
  const [maxId, setMaxId] = useState(0);
  const [imagePreview, setImagePreview] = useState("");
  const [pdfPreview, setPdfPreview] = useState("");
  const [pdfFilename, setPdfFilename] = useState("");
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    fetchMaxId();
  }, []);

  const fetchMaxId = async () => {
    if (!client) return;

    try {
      const command = new ScanCommand({
        TableName: "allSeeds",
        ProjectionExpression: "id",
      });

      const { Items } = await client.send(command);
      const ids = Items
        .map(item => item.id.S)
        .filter(id => !isNaN(id))
        .map(id => parseInt(id))
        .filter(Number.isInteger);

      const maxId = ids.length > 0 ? Math.max(...ids) : 0;
      setMaxId(maxId);
    } catch (error) {
      console.error("Error fetching max ID:", error);
    }
  };

  const handleFileUpload = async (file, folder) => {
    try {
      const response = await fetch("https://id6eckqs6kz7fgg6euu7jptor40mqgul.lambda-url.us-east-2.on.aws/", {
        method: "POST",
        body: JSON.stringify({
          name: file.name,
          type: file.type,
          content: await fileToBase64(file),
          folder,
        }),
      });

      const data = await response.json();
      if (response.ok) {
        console.log(`Uploaded URL: ${data.url}`);
        if (folder === "seeds") {
          setImageUrl(data.url);
          setImagePreview(data.url);
        } else if (folder === "reports") {
          setTestPdf(data.url);
          setPdfPreview(data.url);
          setPdfFilename(file.name);
        }
      } else {
        console.error("Error uploading file:", data.error);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleReset = () => {
    setName("");
    setPrice("");
    setDescription("");
    setDescription2("");
    setFullDescription("");
    setImageUrl("");
    setInventory("");
    setIsSoldOut(false);
    setCollection("");
    setTestPdf("");
    setImagePreview("");
    setPdfPreview("");
    setPdfFilename("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    const isActive = true; 
    const isSoldOut = Boolean(inventory === "0" || inventory === 0 || isSoldOut);  
  
    if (!name || !price || !collection) {
      alert("Please ensure all required fields (Name, Price, Collection) are filled out.");
      setLoading(false);
      return;
    }
  
    const newListing = {
      id: { S: (maxId + 1).toString() },
      createdDate: { S: formatDate(new Date()) },
      collection: { S: collection },
      fullDescription: { S: fullDescription || "" },
      description: { S: description || "" },
      description2: { S: description2 || "" },
      image_url: { S: imageUrl || "" },
      inventory: { N: inventory ? inventory.toString() : "0" },
      name: { S: name },
      price: { N: price ? price.toString() : "0" },
      test_pdf: { S: testPdf || "" },
      isActive: { BOOL: isActive },
      isSoldOut: { BOOL: isSoldOut },
      isFeatured: { BOOL: false },
      isNewArrival: { BOOL: false },
      isOnSale: { BOOL: false },
    };
  
    Object.keys(newListing).forEach(key => {
      if (newListing[key].S === "") {
        delete newListing[key];
      }
    });
  
    try {
      const command = new PutItemCommand({
        TableName: "allSeeds",
        Item: newListing,
      });
  
      await client.send(command);
      alert("Listing created successfully!");
      handleReset();
      fetchMaxId();
    } catch (error) {
      console.error("Error creating listing:", error.message);
      alert(`Error creating listing: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Create Listing
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Name"
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Price"
                fullWidth
                type="number"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Description
              </Typography>
              <ReactQuill
                value={fullDescription}
                onChange={(value) => setFullDescription(value)}
                style={{ marginBottom: '20px' }}
              />
            </Grid>
            <Grid item xs={12}>
              <label htmlFor="image-upload">
                <CustomInput
                  id="image-upload"
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileUpload(e.target.files[0], 'seeds')}
                />
                <UploadButton
                  variant="contained"
                  component="span"
                  startIcon={<Image />}
                >
                  Upload Image
                </UploadButton>
              </label>
              {imagePreview && <PreviewImage src={imagePreview} alt="Seed Preview" />}
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Collection"
                fullWidth
                value={collection}
                onChange={(e) => setCollection(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <label htmlFor="pdf-upload">
                <CustomInput
                  id="pdf-upload"
                  type="file"
                  accept="application/pdf"
                  onChange={(e) => handleFileUpload(e.target.files[0], 'reports')}
                />
                <UploadButton
                  variant="contained"
                  component="span"
                  startIcon={<Description />}
                >
                  Upload PDF
                </UploadButton>
              </label>
              {pdfPreview && (
                <>
                  <IconButton
                    color="primary"
                    onClick={() => setOpenModal(true)}
                    sx={{ marginTop: 1 }}
                  >
                    <Description />
                  </IconButton>
                  <Typography variant="body2">{pdfFilename}</Typography>
                </>
              )}
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            startIcon={loading ? <CircularProgress size={24} /> : <CloudUpload />}
            disabled={loading}
            sx={{ mt: 3, mb: 2 }}
          >
            {loading ? "Creating..." : "Create Listing"}
          </Button>
        </Box>
      </Box>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              height: "80%",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              outline: "none",
            }}
          >
            <IconButton
              color="inherit"
              onClick={() => setOpenModal(false)}
              sx={{ position: "absolute", top: 8, right: 8 }}
            >
              <Close />
            </IconButton>
            <iframe
              src={pdfPreview}
              title="PDF Preview"
              style={{ width: "100%", height: "100%", border: "none" }}
            />
          </Box>
        </Fade>
      </Modal>
    </Container>
  );
};

export default ListingForm;

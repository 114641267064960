import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { CssBaseline, Box, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import Dashboard from './views/dashboard/Dashboard';
import UserTable from './views/users/UserTable';
import UserOrderHistoryContent from './views/users/UserOrderHistoryContent';
import OrderTable from './views/orders/OrderTable';
import OrderDetail from './views/orders/OrderDetail';
import RewardTable from './views/rewards/RewardTable';
import ListingPage from './views/listing/ListingPage';
import ActiveListingsPage from './views/listing/ActiveListingsPage';
import InactiveListingsPage from './views/listing/InactiveListingsPage';
import EditListingPage from './views/listing/EditListingPage';
import ListingForm from './views/listing/ListingForm';
import PendingTestersTable from './views/tester/PendingTestersTable';
import SeedRequestsTable from './views/pending/SeedRequestsTable';
import Sidebar from './components/Sidebar'; 
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import MyAppBar from './components/AppBar';



Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: "6b90agk2p4g04g5m2tpc27rejv",
      userPoolId: "us-east-2_xzDnCbCEO",
      loginWith: {
        email: "true",
      },
    },
  },
});

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#ff0000",
    },
    background: {
      default: "#f5f5f5",
    },
    text: {
      primary: "#212121",
    },
    success: {
      main: "#4caf50",
    },
    warning: {
      main: "#ff9800",
    },
    error: {
      main: "#e53935",
    },
  },
});

const drawerWidth = 280;
const collapsedDrawerWidth = 60;


const AuthBackground = styled(Box)(({ theme }) => ({
  backgroundImage: 'url("./Admin Portal.png")', 
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, isMobile }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    width: '100%',
  }),
);

function App() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <ThemeProvider theme={theme}>
      <AuthBackground>
        <Authenticator variation="modal">
          {({ signOut }) => (
            <Router>
              <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <MyAppBar handleDrawerToggle={handleDrawerToggle} />
                <Main>
                  <Toolbar />
                  <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/users" element={<UserTable />} />
                  <Route path="/users/:userId" element={<UserOrderHistoryContent />} />
                  <Route path="/users/pending" element={<PendingTestersTable />} />
                  <Route path="/users/seed-requests" element={<SeedRequestsTable />} />
                  <Route path="/orders" element={<OrderTable />} />
                  <Route path="/orders/fulfilled" element={<OrderTable fulfilled={true} />} />
                  <Route path="/orders/unfulfilled" element={<OrderTable fulfilled={false} />} />
                  <Route path="/orders/:orderId" element={<OrderDetail />} />
                  <Route path="/rewards" element={<RewardTable />} />
                  <Route path="/listings" element={<ListingPage />} />
                  <Route path="/listings/active" element={<ActiveListingsPage />} />
                  <Route path="/listings/inactive" element={<InactiveListingsPage />} />
                  <Route path="/create-listing" element={<ListingForm />} />
                  <Route path="/listings/edit/:id/:createdDate" element={<EditListingPage />} />
                </Routes>
              </Main>
            </Box>
          </Router>
          )}
          </Authenticator>
          </AuthBackground>
        </ThemeProvider>
      
  );
}

export default App;
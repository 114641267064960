import React, { useEffect, useState, useCallback } from 'react';
import { Box, Paper, Typography, Button, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, Tooltip, Zoom, Fade, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { Check, Close, Agriculture, Search, Refresh, EmojiNature, EnergySavingsLeaf } from '@mui/icons-material';
import { DynamoDBClient, ScanCommand, UpdateItemCommand } from "@aws-sdk/client-dynamodb";
import awsconfig from "../../awsconfig";
import { styled, keyframes } from '@mui/system';
import { motion, AnimatePresence } from 'framer-motion';
import getAwsConfig from "../../awsconfig";

// const client = new DynamoDBClient({
//   region: awsconfig.region,
//   credentials: awsconfig.credentials,
// });

const AnimatedTableRow = styled(motion.tr)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    transform: 'scale(1.01)',
    boxShadow: theme.shadows[4],
  },
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.selectedOpacity,
  },
}));

const AnimatedTableBody = styled(TableBody)({
  '& > tr': {
    opacity: 0,
  },
});

const StyledSearchBar = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[4],
  marginBottom: theme.spacing(3),
  transition: 'box-shadow 0.3s ease-in-out',
  '&:hover': {
    boxShadow: theme.shadows[8],
  },
}));

const StyledSearchInput = styled('input')(({ theme }) => ({
  flex: 1,
  border: 'none',
  outline: 'none',
  padding: theme.spacing(1),
  fontSize: '1rem',
  backgroundColor: 'transparent',
  transition: 'background-color 0.3s ease-in-out',
  '&:focus': {
    backgroundColor: theme.palette.action.focus,
  },
}));

const GrowAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

const SeedIcon = styled(Agriculture)(({ theme }) => ({
  fontSize: 60,
  color: theme.palette.primary.main,
  animation: `${GrowAnimation} 2s ease-in-out infinite`,
}));

const ConfirmationDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: theme.palette.background.paper,
    backgroundImage: `linear-gradient(45deg, ${theme.palette.primary.light}, ${theme.palette.success.light})`,
    borderRadius: theme.shape.borderRadius * 2,
    boxShadow: theme.shadows[8],
    padding: theme.spacing(4),
    textAlign: 'center',
  },
  '& .MuiDialogTitle-root': {
    fontSize: '2rem',
    fontWeight: 'bold',
    color: theme.palette.primary.contrastText,
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
  },
  '& .MuiDialogContent-root': {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  '& .MuiDialogActions-root': {
    justifyContent: 'center',
  },
  '& .MuiButton-root': {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
}));

const SeedRequestsTable = () => {
  const [seedRequesters, setSeedRequesters] = useState([]);
  const [filteredRequesters, setFilteredRequesters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRequesters, setSelectedRequesters] = useState([]);
  const [confirmDialog, setConfirmDialog] = useState({ open: false, action: null, requester: null });
  const [client, setClient] = useState(null);

  useEffect(() => {
    async function fetchAwsConfig() {
      const awsconfig = await getAwsConfig();
      const client = new DynamoDBClient(awsconfig);
      setClient(client);
    }

    fetchAwsConfig();
  }, []);

  const fetchSeedRequesters = async () => {
    if (!client) return; // Ensure client is available
  
    try {
      const input = {
        TableName: "users",
        FilterExpression: "requestedSeeds = :requested",
        ExpressionAttributeValues: {
          ":requested": { BOOL: true }
        }
      };
      const command = new ScanCommand(input);
      const data = await client.send(command);
      return data.Items;
    } catch (err) {
      console.error("Error scanning items:", err);
      throw err;
    }
  };
  
  const updateUserSeedStatus = async (email, approved) => {
    if (!client) return; // Ensure client is available

    try {
      const params = {
        TableName: "users",
        Key: {
          email: { S: email },
          id: { S: email }
        },
        UpdateExpression: approved 
          ? "SET requestedSeeds = :false, testerCount = :zero" 
          : "SET requestedSeeds = :false",
        ExpressionAttributeValues: {
          ":false": { BOOL: false },
          ...(approved && { ":zero": { N: "0" } })
        }
      };
  
      const command = new UpdateItemCommand(params);
      await client.send(command);
      console.log("User seed status updated successfully");
    } catch (err) {
      console.error("Error updating user seed status:", err);
    }
  };
  
  const parseSeedRequesters = (items) => {
    return items.map((user) => ({
      id: user.id?.S || '',
      email: user.email?.S || '',
      firstName: user.firstName?.S || '',
      lastName: user.lastName?.S || '',
      testerCount: parseInt(user.testerCount?.N || '0', 10),
    }));
  };

  const fetchRequesterData = useCallback(async () => {
    if (!client) return; // Ensure client is available

    setLoading(true);
    try {
      const requesterData = await fetchSeedRequesters();
      const parsedData = parseSeedRequesters(requesterData);
      setSeedRequesters(parsedData);
      setFilteredRequesters(parsedData);
    } catch (err) {
      console.error("Error fetching seed requester data:", err);
    }
    setLoading(false);
  }, [client]);

  useEffect(() => {
    fetchRequesterData();
  }, [fetchRequesterData]);

  useEffect(() => {
    const filtered = seedRequesters.filter((requester) => {
      const lowercasedQuery = searchQuery.toLowerCase();
      return (
        requester.firstName.toLowerCase().includes(lowercasedQuery) ||
        requester.lastName.toLowerCase().includes(lowercasedQuery) ||
        requester.email.toLowerCase().includes(lowercasedQuery)
      );
    });
    setFilteredRequesters(filtered);
  }, [searchQuery, seedRequesters]);

  const handleSelectRequester = (requesterId) => {
    setSelectedRequesters((prev) => 
      prev.includes(requesterId) ? prev.filter(id => id !== requesterId) : [...prev, requesterId]
    );
  };

  const handleConfirmAction = async (approved) => {
    setLoading(true);
    if (confirmDialog.requester) {
      await updateUserSeedStatus(confirmDialog.requester.email, approved);
    } else {
      await Promise.all(selectedRequesters.map(async (requesterId) => {
        const requester = seedRequesters.find((r) => r.id === requesterId);
        if (requester) {
          await updateUserSeedStatus(requester.email, approved);
        }
      }));
    }
    await fetchRequesterData();
    setLoading(false);
    setSelectedRequesters([]);
    setConfirmDialog({ open: false, action: null, requester: null });
  };

  const handleOpenConfirmDialog = (action, requester = null) => {
    setConfirmDialog({ open: true, action, requester });
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialog({ open: false, action: null, requester: null });
  };

  const isUserSelected = (requesterId) => selectedRequesters.includes(requesterId);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ maxWidth: '100%', margin: 'auto', p: 3 }}>
      <Typography variant={isMobile ? 'h5' : 'h3'} gutterBottom sx={{ fontWeight: 'bold', color: 'primary.main', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', textTransform: 'uppercase', letterSpacing: 4, textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)' }}>
        <SeedIcon sx={{ mr: 2 }} /> Seed Requests
      </Typography>
      <Fade in={true}>
        <Paper elevation={8} sx={{ p: 3, borderRadius: 4, backgroundColor: 'background.paper', backgroundImage: 'linear-gradient(45deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.05))', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)' }}>
          <StyledSearchBar>
            <Search sx={{ mr: 2, color: 'text.secondary', fontSize: 28 }} />
            <StyledSearchInput
              placeholder="Search requesters..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Tooltip title="Refresh" TransitionComponent={Zoom} arrow>
              <IconButton onClick={fetchRequesterData} disabled={loading} sx={{ ml: 1, transition: 'transform 0.3s ease-in-out', '&:hover': { transform: 'rotate(360deg)' } }}>
                <Refresh sx={{ fontSize: 28 }} />
              </IconButton>
            </Tooltip>
          </StyledSearchBar>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox" />
                  <TableCell sx={{ fontWeight: 'bold', fontSize: 18 }}>First Name</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', fontSize: 18 }}>Last Name</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', fontSize: 18 }}>Email</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', fontSize: 18 }}>Current Tester Count</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', fontSize: 18 }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <AnimatedTableBody>
                <AnimatePresence>
                  {filteredRequesters.map((requester, index) => {
                    const isSelected = isUserSelected(requester.id);

                    return (
                      <AnimatedTableRow
                        key={requester.id}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.4, delay: index * 0.1, type: 'spring', stiffness: 300, damping: 20 }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isSelected}
                            onChange={() => handleSelectRequester(requester.id)}
                            icon={<EnergySavingsLeaf />}
                            checkedIcon={<EmojiNature />}
                          />
                        </TableCell>
                        <TableCell sx={{ fontSize: 16 }}>{requester.firstName}</TableCell>
                        <TableCell sx={{ fontSize: 16 }}>{requester.lastName}</TableCell>
                        <TableCell sx={{ fontSize: 16 }}>{requester.email}</TableCell>
                        <TableCell sx={{ fontSize: 16 }}>{requester.testerCount}</TableCell>
                        <TableCell>
                          <Tooltip title="Approve Request" TransitionComponent={Zoom} arrow>
                            <Button
                              variant="contained"
                              color="success"
                              size={isMobile ? 'small' : 'medium'}
                              onClick={() => handleOpenConfirmDialog('approve', requester)}
                              sx={{ mr: 1, textTransform: 'uppercase', fontWeight: 'bold', transition: 'transform 0.3s ease-in-out', '&:hover': { transform: 'scale(1.05)' } }}
                            >
                              <Check sx={{ mr: isMobile ? 0 : 1 }} /> {isMobile ? '' : 'Approve'}
                            </Button>
                          </Tooltip>
                          <Tooltip title="Reject Request" TransitionComponent={Zoom} arrow>
                            <Button
                              variant="contained"
                              color="error"
                              size={isMobile ? 'small' : 'medium'}
                              onClick={() => handleOpenConfirmDialog('reject', requester)}
                              sx={{ textTransform: 'uppercase', fontWeight: 'bold', transition: 'transform 0.3s ease-in-out', '&:hover': { transform: 'scale(1.05)' } }}
                            >
                              <Close sx={{ mr: isMobile ? 0 : 1 }} /> {isMobile ? '' : 'Reject'}
                            </Button>
                          </Tooltip>
                        </TableCell>
                      </AnimatedTableRow>
                    );
                  })}
                </AnimatePresence>
              </AnimatedTableBody>
            </Table>
          </TableContainer>
          <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center', justifyContent: 'space-between', mt: 3 }}>
            <Typography variant="h6" color="text.secondary" sx={{ mb: isMobile ? 2 : 0 }}>
              {selectedRequesters.length} requester{selectedRequesters.length !== 1 ? 's' : ''} selected
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
              <Tooltip title="Approve Selected Requests" TransitionComponent={Zoom} arrow>
                <span>
                  <Button
                    variant="contained"
                    color="success"
                    size={isMobile ? 'small' : 'large'}
                    onClick={() => handleOpenConfirmDialog('approve')}
                    disabled={loading || selectedRequesters.length === 0}
                    startIcon={loading ? <CircularProgress size={20} /> : <Check />}
                    sx={{ mr: isMobile ? 0 : 2, mb: isMobile ? 2 : 0, textTransform: 'uppercase', fontWeight: 'bold', transition: 'transform 0.3s ease-in-out', '&:hover': { transform: 'scale(1.05)' } }}
                  >
                    Approve Selected
                  </Button>
                </span>
              </Tooltip>
              <Tooltip title="Reject Selected Requests" TransitionComponent={Zoom} arrow>
                <span>
                  <Button
                    variant="contained"
                    color="error"
                    size={isMobile ? 'small' : 'large'}
                    onClick={() => handleOpenConfirmDialog('reject')}
                    disabled={loading || selectedRequesters.length === 0}
                    startIcon={loading ? <CircularProgress size={20} /> : <Close />}
                    sx={{ textTransform: 'uppercase', fontWeight: 'bold', transition: 'transform 0.3s ease-in-out', '&:hover': { transform: 'scale(1.05)' } }}
                  >
                    Reject Selected
                  </Button>
                </span>
              </Tooltip>
            </Box>
          </Box>
        </Paper>
      </Fade>
      <Fade in={seedRequesters.length === 0}>
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <SeedIcon />
          <Typography variant={isMobile ? 'h6' : 'h4'} color="text.secondary" sx={{ mt: 2 }}>
            No pending seed requests
          </Typography>
        </Box>
      </Fade>

      <ConfirmationDialog
        open={confirmDialog.open}
        onClose={handleCloseConfirmDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Zoom}
        transitionDuration={500}
      >
        <DialogTitle id="alert-dialog-title">
          {`Confirm ${confirmDialog.action === 'approve' ? 'Approval' : 'Rejection'}`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ fontSize: '1.2rem' }}>
            {confirmDialog.requester
              ? `Are you sure you want to ${confirmDialog.action} the seed request for ${confirmDialog.requester.firstName} ${confirmDialog.requester.lastName}?`
              : `Are you sure you want to ${confirmDialog.action} the selected seed requests?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseConfirmDialog}
            color="primary"
            variant="contained"
            size="large"
            sx={{ width: '120px' }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleConfirmAction(confirmDialog.action === 'approve')}
            color={confirmDialog.action === 'approve' ? 'success' : 'error'}
            variant="contained"
            size="large"
            autoFocus
            startIcon={confirmDialog.action === 'approve' ? <Check /> : <Close />}
            sx={{ width: '120px' }}
          >
            {confirmDialog.action === 'approve' ? 'Approve' : 'Reject'}
          </Button>
        </DialogActions>
      </ConfirmationDialog>
    </Box>
  );
};

export default SeedRequestsTable;
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import { getCurrentUser } from 'aws-amplify/auth';
// Assuming fetchAuthSession is a valid function you have implemented elsewhere
import { fetchAuthSession } from 'aws-amplify/auth';

export async function getCredentials() {
  try {
    // Try to get the current authenticated user
    await getCurrentUser();
    
    // Assuming fetchAuthSession is a valid function that fetches the session
    const credentials = await fetchAuthSession();
    
    // Validate if credentials object contains necessary properties
    if (!credentials.accessKeyId || !credentials.secretAccessKey || !credentials.sessionToken) {
      throw new Error('Invalid credentials object');
    }
    
    return {
      accessKeyId: credentials.accessKeyId,
      secretAccessKey: credentials.secretAccessKey,
      sessionToken: credentials.sessionToken,
    };
  } catch (error) {
    console.error('Error getting authenticated user or session:', error);
    
    // Use Cognito Identity Pool for unauthenticated access
    const credentials = await fromCognitoIdentityPool({
      clientConfig: { region: 'us-east-2' },
      identityPoolId: 'us-east-2:ef1d578a-8f02-4984-b15f-c5ade001a098',
    })();
    
    // Validate if credentials object contains necessary properties
    if (!credentials.accessKeyId || !credentials.secretAccessKey || !credentials.sessionToken) {
      throw new Error('Invalid credentials object from Cognito Identity Pool');
    }
    
    return {
      accessKeyId: credentials.accessKeyId,
      secretAccessKey: credentials.secretAccessKey,
      sessionToken: credentials.sessionToken,
    };
  }
}

export default async function getAwsConfig() {
  const credentials = await getCredentials();
  const awsconfig = {
    region: "us-east-2",
    credentials: credentials,
  };
  
  return awsconfig;
}

import React, { useRef } from 'react';
import { Grid, Typography, Fade } from "@mui/material";
import { ListingCard, ListingImage, ListingContent, ListingTitle, ListingPrice, ListingDescription, StatusIndicator } from './StyledComponents';

const ListingsGrid = ({ listings, loading, onListingClick }) => {
  const cardRefs = useRef([]);

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Grid container justifyContent="center">
      {listings.map((listing, index) => (
        <Fade in timeout={index * 200} key={listing.id}>
          <ListingCard
            onClick={() => onListingClick(listing.id, listing.createdDate)}
            ref={el => (cardRefs.current[index] = el)}
          >
            <StatusIndicator isActive={listing.isActive}>
              {listing.isActive ? 'Active' : 'Inactive'}
            </StatusIndicator>
            <ListingImage src={listing.imageUrl} alt={listing.name} />
            <ListingContent>
              <ListingTitle>{listing.name}</ListingTitle>
              <ListingPrice>${listing.price}</ListingPrice>
              <ListingDescription>{listing.description}</ListingDescription>
            </ListingContent>
          </ListingCard>
        </Fade>
      ))}
    </Grid>
  );
};

export default ListingsGrid;